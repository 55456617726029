define("ember-social-share/templates/components/email-share-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BIl738xd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[12,\"href\",[28,[\"mailto:\",[21,\"recipient\"],\"?subject=\",[21,\"title\"],\"&body=\",[21,\"text\"]]]],[9],[0,\"\\n   \"],[1,[27,\"fa-icon\",[\"envelope\"],[[\"size\"],[\"lg\"]]],false],[0,\"\\n   \"],[7,\"span\"],[9],[14,1],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-social-share/templates/components/email-share-button.hbs"
    }
  });
  _exports.default = _default;
});