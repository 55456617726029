define("ember-local-settings/local-settings-interface", ["exports", "ember-local-settings/mixins/settings-interface"], function (_exports, _settingsInterface) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * An object implementing a settings interface.
   *
   * @extends Ember.Object
   * @uses SettingsInterfaceMixin
   */
  var LocalSettingsInterface = Ember.Object.extend(_settingsInterface.default, {
    /**
     * Create a "branch" -- a futher prefixed settings interface. The prefix is
     * appended to this service's prefix.
     *
     * @param prefix
     * @returns {*} settings interface
     */
    createBranch: function createBranch(prefix) {
      return LocalSettingsInterface.create({
        config: this.get('config'),
        prefix: this.get('prefix') + prefix
      });
    }
  });
  LocalSettingsInterface.reopenClass({
    toString: function toString() {
      return "ember-local-settings/interface";
    }
  });
  var _default = _exports.default = LocalSettingsInterface;
});