define("ember-composability-tools/mixins/child", ["exports", "ember-composability-tools/mixins/parent"], function (_exports, _parent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // This is intended as an escape hatch, but ideally you would
    // `{{yield` a child contextual component with `parentComponent=this`
    parentComponent: Ember.computed({
      get: function get() {
        if (this._parentComponent) {
          return this._parentComponent;
        }
        return this.nearestOfType(_parent.default);
      },
      set: function set(key, value) {
        return this._parentComponent = value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.tryInvoke(this, 'initParent');
      Ember.tryInvoke(this, 'initChild');
    },
    initChild: function initChild() {
      this.registerWithParent();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!this._isComposableDestroying) {
        this._isComposableDestroying = true;
        Ember.tryInvoke(this, 'willDestroyElementParent');
        Ember.tryInvoke(this, 'willDestroyElementChild');
      }
    },
    willDestroyElementChild: function willDestroyElementChild() {
      this._super.apply(this, arguments);
      this.unregisterWithParent();
    },
    shouldRegister: true,
    shouldRegisterToParent: function shouldRegisterToParent() /*parentComponent*/{
      return this.get('shouldRegister');
    },
    destroySelfAndChildren: function destroySelfAndChildren() {
      // We may be a child-parent. Destroy children if we can.
      Ember.tryInvoke(this, 'destroyChildren');
      Ember.tryInvoke(this, 'willDestroyParent');
      this._didInsert = false;
    },
    registerWithParent: function registerWithParent() {
      var parentComponent = this.get('parentComponent');
      if (parentComponent && this.shouldRegisterToParent(parentComponent)) {
        parentComponent.registerChild(this);
      }
    },
    unregisterWithParent: function unregisterWithParent() {
      var parentComponent = this.get('parentComponent');
      if (parentComponent) {
        parentComponent.unregisterChild(this);
      }
    }
  });
});