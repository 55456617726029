define("ember-power-select-typeahead/templates/components/power-select-typeahead/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "i1cN+1UB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"value\",[21,\"text\"]],[11,\"role\",\"combobox\"],[12,\"id\",[28,[\"ember-power-select-typeahead-input-\",[23,[\"select\",\"uniqueId\"]]]]],[11,\"class\",\"ember-power-select-typeahead-input ember-power-select-search-input\"],[11,\"autocomplete\",\"off\"],[11,\"autocorrect\",\"off\"],[11,\"autocapitalize\",\"off\"],[11,\"spellcheck\",\"false\"],[12,\"placeholder\",[21,\"placeholder\"]],[12,\"oninput\",[21,\"onInput\"]],[12,\"onfocus\",[21,\"onFocus\"]],[12,\"onblur\",[21,\"onBlur\"]],[12,\"disabled\",[23,[\"select\",\"disabled\"]]],[12,\"onkeydown\",[27,\"action\",[[22,0,[]],\"handleKeydown\"],null]],[12,\"onmousedown\",[27,\"action\",[[22,0,[]],\"stopPropagation\"],null]],[11,\"type\",\"search\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"select\",\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"ember-power-select-typeahead-loading-indicator\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select-typeahead/templates/components/power-select-typeahead/trigger.hbs"
    }
  });
  _exports.default = _default;
});