define("ember-local-settings/adapters", ["exports", "ember-local-settings/adapters/cookie", "ember-local-settings/adapters/local-memory", "ember-local-settings/adapters/local-storage", "ember-local-settings/adapters/session-storage"], function (_exports, _cookie, _localMemory, _localStorage, _sessionStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "cookie": _cookie.default,
    "local-memory": _localMemory.default,
    "local-storage": _localStorage.default,
    "session-storage": _sessionStorage.default
  };
});