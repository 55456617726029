define("ember-leaflet/helpers/ember-leaflet-assign-to", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberLeafletAssignTo = emberLeafletAssignTo;
  function emberLeafletAssignTo(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
      object = _ref3[0];
    var key = _ref2.key,
      value = _ref2.value,
      onChange = _ref2.onChange;
    object[key] = value;

    // we need to send out the assigned object because ember freezes helper arguments
    onChange(object);
  }
  var _default = _exports.default = Ember.Helper.helper(emberLeafletAssignTo);
});