define("ember-local-settings/mixins/settings-interface", ["exports", "ember-local-settings/adapters", "ember-local-settings/serializers"], function (_exports, _adapters, _serializers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A mixin implementing an interface to local settings
   *
   * @class SettingsInterfaceMixin
   * @private
   */
  var _default = _exports.default = Ember.Mixin.create({
    /**
     * The adapter object used to store values
     *
     * @property adapter
     */
    adapter: Ember.computed('config.adapter', function () {
      var name = this.get('config.adapter');
      var cls = _adapters.default[name];
      if (!cls) {
        throw new Error("Unrecognized local settings adapter: '".concat(name, "'"));
      }
      return cls.create({
        config: this.get('config')
      });
    }),
    /**
     * The serializer object used to serialize/deserialize values
     *
     * @property serializer
     */
    serializer: Ember.computed('config.serializer', function () {
      var name = this.get('config.serializer');
      var cls = _serializers.default[name];
      if (!cls) {
        throw new Error("Unrecognized local settings serializer: '".concat(name, "'"));
      }
      return cls.create({
        config: this.get('config')
      });
    }),
    /**
     * The prefix for stored values. This can be used to implement namespaces or
     * paths or a similar mechanism for modularizing settings values. Any keys
     * passed into methods on this object will have this prefix prepended to them
     * before they are passed to the adapter.
     *
     * @property prefix
     */
    prefix: '',
    /**
     * Settings proxy object. The get() and set() methods on this object
     * are forwarded to getValue and setValue, and can be used as direct
     * accessors to stored values.
     *
     * @property settings
     */
    settings: Ember.computed(function () {
      // Play a little trick so we don't have to set any properties on the
      // settings object that could conflict with key names.
      var localSettings = this;
      var Settings = Ember.Object.extend({
        unknownProperty: function unknownProperty(key) {
          return localSettings.getValue(key);
        },
        setUnknownProperty: function setUnknownProperty(key, value) {
          var ret = localSettings.setValue(key, value);
          this.notifyPropertyChange(key);
          return ret;
        }
      });
      return Settings.create();
    }),
    /**
     * Get a value.
     *
     * @param {String} key
     * @returns {*}
     */
    getValue: function getValue(key) {
      var value = this.get('adapter').getValue("".concat(this.get('prefix')).concat(key));
      return this.get('serializer').deserialize(value);
    },
    /**
     * Set a value.
     *
     * @param {String} key
     * @param {*} value
     * @returns {*} value
     */
    setValue: function setValue(key, value) {
      key = "".concat(this.get('prefix')).concat(key);
      var adapter = this.get('adapter');
      if (value === null || value === undefined) {
        adapter.deleteValue(key);
        return value;
      }
      adapter.setValue(key, this.get('serializer').serialize(value));
      return adapter.getValue(key);
    },
    /**
     * Get all stored keys
     *
     * @returns {Array}
     */
    getKeys: function getKeys() {
      var prefix = this.get('prefix');
      var keys = [];
      Ember.A(this.get('adapter').getKeys()).forEach(function (key) {
        if (key.substring(0, prefix.length) === prefix) {
          keys.push(key.substring(prefix.length));
        }
      });
      return keys;
    }
  });
});