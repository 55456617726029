define("ember-leaflet/components/circle-layer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-leaflet/components/point-path-layer"], function (_exports, _toConsumableArray2, _pointPathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _pointPathLayer.default.extend({
    leafletRequiredOptions: Object.freeze(['radius']),
    leafletProperties: Object.freeze(['radius']),
    createLayer: function createLayer() {
      var _this$L;
      return (_this$L = this.L).circle.apply(_this$L, (0, _toConsumableArray2.default)(this.get('requiredOptions')).concat([this.get('options')]));
    }
  });
});