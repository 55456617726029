define('ember-responsive/services/media', ['exports', '@ember/string', 'ember-responsive/null-match-media'], function (exports, _string, _nullMatchMedia) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend(Ember.Evented, {
    _mocked: Ember.testing,
    _mockedBreakpoint: 'desktop',
    /**
    * A set of matching matchers.
    *
    * @property  matches
    * @type      Ember.NativeArray
    * @default   Ember.NativeArray
    */
    matches: Ember.computed(function () {
      return Ember.A(this.get('_mocked') ? [this.get('_mockedBreakpoint')] : []);
    }),

    /**
      * A hash of listeners indexed by their matcher's names
      *
      * @property
      * @type Object
      */
    listeners: {},

    /**
     * A hash of matchers by breakpoint name
     */
    matchers: {},

    /**
    * The matcher to use for testing media queries.
    *
    * @property  matcher
    * @type      matchMedia
    * @default   window.matchMedia
    * @private
    */
    mql: detectMatchMedia(),

    /**
     * Initialize the service based on the breakpoints config
     *
     * @method init
     *
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var owner = Ember.getOwner(this);
      var breakpoints = Ember.getOwner(this).lookup('breakpoints:main');
      if (breakpoints) {
        Object.keys(breakpoints).forEach(function (name) {
          var cpName = 'is' + (0, _string.classify)(name);
          Ember.defineProperty(_this, cpName, Ember.computed('matches.[]', function () {
            return this.get('matches').indexOf(name) > -1;
          }));
          Ember.defineProperty(_this, name, Ember.computed(cpName, function () {
            return this.get(cpName);
          }));
          _this.match(name, breakpoints[name]);
        });
      }
    },


    /**
    * A string composed of all the matching matchers' names, turned into
    * friendly, dasherized class-names that are prefixed with `media-`.
    *
    * @property  classNames
    * @type      string
    */
    classNames: Ember.computed('matches.[]', function () {
      return this.get('matches').map(function (name) {
        return 'media-' + (0, _string.dasherize)(name);
      }).join(' ');
    }),

    _triggerMediaChanged: function _triggerMediaChanged() {
      this.trigger('mediaChanged', {});
    },
    _triggerEvent: function _triggerEvent() {
      Ember.run.once(this, this._triggerMediaChanged);
    },


    /**
    * Adds a new matcher to the list.
    *
    * After this method is called, you will be able to access the result
    * of the matcher as a property on this object.
    *
    * **Adding a new matcher**
    *
    * ```javascript
    * media = Ember.Responsive.Media.create();
    * media.match('all', 'all');
    * media.get('all');
    *   // => instanceof window.matchMedia
    * media.get('all.matches');
    *   // => true
    * ```
    *
    * @param   string  name   The name of the matcher
    * @param   string  query  The media query to match against
    * @method  match
    */
    match: function match(name, query) {
      var _this2 = this;

      if (this.get('_mocked')) {
        return;
      }

      var matcher = this.get('mql')(query);

      var listener = function listener(matcher) {
        if (_this2.get('isDestroyed')) {
          return;
        }

        _this2.set('matchers.' + name, matcher);

        if (matcher.matches) {
          _this2.get('matches').addObject(name);
        } else {
          _this2.get('matches').removeObject(name);
        }
        _this2._triggerEvent();
      };
      this.get('listeners')[name] = listener;

      if (matcher.addListener) {
        matcher.addListener(function (matcher) {
          Ember.run(null, listener, matcher);
        });
      }
      listener(matcher);
    }
  });


  function detectMatchMedia() {
    if ((typeof window === 'undefined' ? 'undefined' : _typeof(window)) === 'object' && window.matchMedia) {
      return window.matchMedia;
    }

    return _nullMatchMedia.default;
  }
});