define("ember-power-select-typeahead/components/power-select-typeahead/trigger", ["exports", "ember-power-select-typeahead/templates/components/power-select-typeahead/trigger"], function (_exports, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _trigger.default,
    tagName: '',
    text: '',
    /**
     * Lifecycle Hook
     * power-select updates the state of the publicAPI (select) for every typeahead
     * so we capture this as `state` via oldSelect && newSelect
     *
     * @private
     * @method didReceiveAttrs
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var oldSelect = this.get('oldSelect');
      var newSelect = this.set('oldSelect', this.get('select'));
      if (!oldSelect) {
        return;
      }
      /*
       * We need to update the input field with value of the selected option whenever we're closing
       * the select box.
       */
      if (oldSelect.isOpen && !newSelect.isOpen && newSelect.searchText) {
        var input = document.querySelector("#ember-power-select-typeahead-input-".concat(newSelect.uniqueId));
        var newText = this.getSelectedAsText();
        if (input.value !== newText) {
          input.value = newText;
        }
        this.set('text', newText);
      }
      if (newSelect.lastSearchedText !== oldSelect.lastSearchedText) {
        if (Ember.isBlank(newSelect.lastSearchedText)) {
          Ember.run.schedule('actions', null, newSelect.actions.close, null, true);
        } else {
          Ember.run.schedule('actions', null, newSelect.actions.open);
        }
      }
      if (oldSelect.selected !== newSelect.selected) {
        this.set('text', this.getSelectedAsText());
      }
    },
    actions: {
      /**
       * on mousedown prevent propagation of event
       *
       * @private
       * @method stopPropagation
       * @param {Object} event
       */
      stopPropagation: function stopPropagation(e) {
        e.stopPropagation();
      },
      /**
       * called from power-select internals
       *
       * @private
       * @method handleKeydown
       * @param {Object} event
       */
      handleKeydown: function handleKeydown(e) {
        // up or down arrow and if not open, no-op and prevent parent handlers from being notified
        if ([38, 40].indexOf(e.keyCode) > -1 && !this.get('select.isOpen')) {
          e.stopPropagation();
          return;
        }
        var isLetter = e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32; // Keys 0-9, a-z or SPACE
        // if isLetter, escape or enter, prevent parent handlers from being notified
        if (isLetter || [13, 27].indexOf(e.keyCode) > -1) {
          var select = this.get('select');
          // open if loading msg configured
          if (!select.isOpen && this.get('loadingMessage')) {
            Ember.run.schedule('actions', null, select.actions.open);
          }
          e.stopPropagation();
        }

        // optional, passed from power-select
        var onkeydown = this.get('onKeydown');
        if (onkeydown && onkeydown(e) === false) {
          return false;
        }
      }
    },
    /**
     * obtains seleted value based on complex object or primitive value from power-select publicAPI
     *
     * @private
     * @method getSelectedAsText
     */
    getSelectedAsText: function getSelectedAsText() {
      var labelPath = this.get('extra.labelPath');
      var value;
      if (labelPath) {
        // complex object
        value = this.get("select.selected.".concat(labelPath));
      } else {
        // primitive value
        value = this.get('select.selected');
      }
      if (value === undefined) {
        value = '';
      }
      return value;
    }
  });
});