define("ember-leaflet-google-mutant-layer/components/google-mutant-layer", ["exports", "ember-leaflet/components/base-layer"], function (_exports, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseLayer.default.extend({
    type: 'roadmap',
    // Possible types: sattelite, roadmap, terrain. hybrid is not really supported
    leafletRequiredOptions: Object.freeze(['type']),
    leafletOptions: Object.freeze(['attribution', 'opacity', 'maxZoom', 'minZoom', 'maxNativeZoom', 'continuousWorld', 'noWrap', 'styles', 'type']),
    leafletEvents: Object.freeze(['load', 'spawned']),
    leafletProperties: Object.freeze(['opacity']),
    isTrafficOn: false,
    isTransitOn: false,
    isBicyclingOn: false,
    isKmlOn: false,
    // eslint-disable-next-line ember/no-observers
    toggleTraffic: Ember.observer('isTrafficOn', function () {
      this.updateGoogleLayer('TrafficLayer', this.get('isTrafficOn'));
    }),
    // eslint-disable-next-line ember/no-observers
    toggleTransit: Ember.observer('isTransitOn', function () {
      this.updateGoogleLayer('TransitLayer', this.get('isTransitOn'));
    }),
    // eslint-disable-next-line ember/no-observers
    toggleBicycling: Ember.observer('isBicyclingOn', function () {
      this.updateGoogleLayer('BicyclingLayer', this.get('isBicyclingOn'));
    }),
    // eslint-disable-next-line ember/no-observers
    toggleKml: Ember.observer('isKmlOn', function () {
      this.updateGoogleLayer('KmlLayer', this.get('isKmlOn'), this.get('kmlLayerOptions'));
    }),
    updateGoogleLayer: function updateGoogleLayer(layer, show, options) {
      this._layer[show ? 'addGoogleLayer' : 'removeGoogleLayer'](layer, options);
    },
    _spawned: function _spawned() {
      this.toggleTraffic();
      this.toggleTransit();
      this.toggleBicycling();
      this.toggleKml();
    },
    createLayer: function createLayer() {
      var options = this.get('options');
      options.type = options.type.toLowerCase();
      return this.L.gridLayer.googleMutant(options);
    }
  });

  _exports.default = _default;
});