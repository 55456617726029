define("ember-cli-head/templates/components/head-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZPPR0Oj/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"in-element\",[[23,[\"headElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"meta\"],[11,\"name\",\"ember-cli-head-start\"],[11,\"content\",\"\"],[9],[10],[1,[21,\"head-content\"],false],[7,\"meta\"],[11,\"name\",\"ember-cli-head-end\"],[11,\"content\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-head/templates/components/head-layout.hbs"
    }
  });
  _exports.default = _default;
});