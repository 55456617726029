define("ember-leaflet-marker-cluster/instance-initializers/register-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    var emberLeafletService = appInstance.lookup('service:ember-leaflet');
    if (emberLeafletService) {
      emberLeafletService.registerComponent('marker-cluster-layer', {
        as: 'marker-cluster'
      });
    }
  }
  var _default = _exports.default = {
    initialize: initialize
  };
});