define("ember-local-settings/instance-initializers/local-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    var env = appInstance.resolveRegistration('config:environment') || {};
    var _env$localSettings = env.localSettings,
      config = _env$localSettings === void 0 ? {
        serializer: 'json',
        adapter: 'local-storage',
        prefix: 'emberApp/'
      } : _env$localSettings;

    // The local memory adapter stores state in the configuration that we want to
    // be isolated by application, so we implement this as an instance initializer
    // that injects a copy of the config into the service.
    appInstance.register('config:local-settings', Ember.assign({}, config), {
      instantiate: false
    });
    appInstance.inject('service:local-settings', 'config', 'config:local-settings');
  }
  var _default = _exports.default = {
    initialize: initialize
  };
});