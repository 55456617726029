define('ember-aria-slider/components/aria-slider-thumb', ['exports', 'ember-aria-slider/templates/components/aria-slider-thumb'], function (exports, _ariaSliderThumb) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RAIL_WIDTH = 300;

  var THUMB_WIDTH = 8;

  var KEYCODES = {
    'left': 37,
    'up': 38,
    'right': 39,
    'down': 40,
    'pageUp': 33,
    'pageDown': 34,
    'end': 35,
    'home': 36
  };

  exports.default = Ember.Component.extend({
    layout: _ariaSliderThumb.default,
    classNames: ['thumb'],
    classNameBindings: ['focusClass:focus'],
    attributeBindings: ['currentValue:aria-valuenow', 'label:aria-label', 'maxValue:aria-valuemax', 'minValue:aria-valuemin', 'role', 'src', 'tabindex', 'valueText:aria-valuetext', 'labelledBy:aria-labelledby'],
    role: 'slider',
    tabindex: 0,
    focusClass: false,
    valueText: Ember.computed('currentValue', function () {
      var units = this.get('units') || '';
      var _valueText = '' + this.get('currentValue') + units;
      if (this.get('unitPrefix')) {
        _valueText = '' + units + this.get('currentValue');
      }
      return _valueText;
    }),

    keyDown: function keyDown(event) {
      var flag = false;

      var currentVal = this.get('currentValue');
      switch (event.keyCode) {
        case KEYCODES.left:
        case KEYCODES.down:
          this.moveSliderTo(currentVal - 1);
          flag = true;
          break;

        case KEYCODES.right:
        case KEYCODES.up:
          this.moveSliderTo(currentVal + 1);
          flag = true;
          break;

        case KEYCODES.pageDown:
          this.moveSliderTo(currentVal - 10);
          flag = true;
          break;

        case KEYCODES.pageUp:
          this.moveSliderTo(currentVal + 10);
          flag = true;
          break;

        case KEYCODES.home:
          this.moveSliderTo(this.get('minValue'));
          flag = true;
          break;

        case KEYCODES.end:
          this.moveSliderTo(this.get('maxValue'));
          flag = true;
          break;

        default:
          break;
      }

      if (flag) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    mouseDown: function mouseDown(e) {
      var _this = this;

      var parentNode = e.target.parentNode;
      var minValue = this.get('minValue');
      var maxValue = this.get('maxValue');

      var handleMouseMove = function handleMouseMove(event) {

        var diffX = event.pageX - parentNode.offsetLeft;
        var valueNow = minValue + parseInt((maxValue - minValue) * diffX / RAIL_WIDTH);
        _this.moveSliderTo(valueNow);

        event.preventDefault();
        event.stopPropagation();
      };

      var handleMouseUp = function handleMouseUp() {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      // bind a mousemove event handler to move pointer
      document.addEventListener('mousemove', handleMouseMove);

      // bind a mouseup event handler to stop tracking mouse movements
      document.addEventListener('mouseup', handleMouseUp);

      e.preventDefault();
      e.stopPropagation();

      // Set focus to the clicked handle
      e.target.focus();
    },
    focusIn: function focusIn() {
      this.set('focusClass', true);
    },
    focusOut: function focusOut() {
      this.set('focusClass', false);
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', function () {
        _this2.moveSliderTo(_this2.get('currentValue'));
      });
    },
    moveSliderTo: function moveSliderTo(value) {
      var minValue = this.get('minValue');
      var maxValue = this.get('maxValue');
      var _value = value;

      if (value < minValue) {
        _value = minValue;
      }

      if (value > maxValue) {
        _value = maxValue;
      }

      this.set('currentValue', _value);

      if (value < minValue || value > maxValue) {
        return;
      }
      var pos = Math.round(_value * RAIL_WIDTH / (maxValue - minValue)) - THUMB_WIDTH / 2;

      // console.log("pos = ", pos);
      var left = '';

      left = pos + 'px';
      // console.log("left = ", left);

      this.element.style.left = left;
    }
  });
});