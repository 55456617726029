/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var LocalityConstants = (function () {
                    function LocalityConstants() {
                    }
                    LocalityConstants.app_name = "app_name";
                    LocalityConstants.contact_email = "contact_email";
                    LocalityConstants.loading_dropzones = "loading_dropzones";
                    LocalityConstants.action_settings = "action_settings";
                    LocalityConstants.action_layers = "action_layers";
                    LocalityConstants.action_search = "action_search";
                    LocalityConstants.action_other = "action_other";
                    LocalityConstants.pref_summary_api_url = "pref_summary_api_url";
                    LocalityConstants.pref_title_api_url = "pref_title_api_url";
                    LocalityConstants.pref_summary_open_altitude = "pref_summary_open_altitude";
                    LocalityConstants.pref_title_open_altitude = "pref_title_open_altitude";
                    LocalityConstants.pref_summary_cutaway_altitude = "pref_summary_cutaway_altitude";
                    LocalityConstants.pref_title_cutaway_time = "pref_title_cutaway_time";
                    LocalityConstants.pref_summary_canopy = "pref_summary_canopy";
                    LocalityConstants.pref_title_canopy = "pref_title_canopy";
                    LocalityConstants.select_canopy = "select_canopy";
                    LocalityConstants.pref_summary_pattern = "pref_summary_pattern";
                    LocalityConstants.pref_title_pattern = "pref_title_pattern";
                    LocalityConstants.pref_summary_auto_adjust_upwind = "pref_summary_auto_adjust_upwind";
                    LocalityConstants.pref_title_auto_adjust_upwind = "pref_title_auto_adjust_upwind";
                    LocalityConstants.pref_title_wind_speed = "pref_title_wind_speed";
                    LocalityConstants.pref_summary_wind_speed = "pref_summary_wind_speed";
                    LocalityConstants.display_layers = "display_layers";
                    LocalityConstants.ok = "ok";
                    LocalityConstants.cancel = "cancel";
                    LocalityConstants.layer_windaloft = "layer_windaloft";
                    LocalityConstants.layer_drift = "layer_drift";
                    LocalityConstants.layer_pattern = "layer_pattern";
                    LocalityConstants.pattern_landing_label = "pattern_landing_label";
                    LocalityConstants.barely_make_back_label = "barely_make_back_label";
                    LocalityConstants.search_hint = "search_hint";
                    LocalityConstants.drawer_open = "drawer_open";
                    LocalityConstants.drawer_close = "drawer_close";
                    LocalityConstants.cutaway = "cutaway";
                    LocalityConstants.draw_cutaway_title = "draw_cutaway_title";
                    LocalityConstants.cutaway_button_title = "cutaway_button_title";
                    LocalityConstants.cutaway_button_date = "cutaway_button_date";
                    LocalityConstants.pref_title_cutaway_time_trial = "pref_title_cutaway_time_trial";
                    LocalityConstants.menuitem_status = "menuitem_status";
                    LocalityConstants.menuitem_add_canopy = "menuitem_add_canopy";
                    LocalityConstants.version_not_supported = "version_not_supported";
                    LocalityConstants.update_message = "update_message";
                    LocalityConstants.version_expired_title = "version_expired_title";
                    LocalityConstants.version_expired_message = "version_expired_message";
                    LocalityConstants.giftcode_activated = "giftcode_activated";
                    LocalityConstants.buy_message = "buy_message";
                    LocalityConstants.edit_dropzone_off = "edit_dropzone_off";
                    LocalityConstants.edit_dropzone_on = "edit_dropzone_on";
                    LocalityConstants.rta_dialog_title = "rta_dialog_title";
                    LocalityConstants.rta_dialog_message = "rta_dialog_message";
                    LocalityConstants.rta_dialog_ok = "rta_dialog_ok";
                    LocalityConstants.rta_dialog_cancel = "rta_dialog_cancel";
                    LocalityConstants.rta_dialog_no = "rta_dialog_no";
                    LocalityConstants.action_glideratio_logs = "action_glideratio_logs";
                    LocalityConstants.action_recording_status = "action_recording_status";
                    LocalityConstants.dropzones_update_now_button = "dropzones_update_now_button";
                    LocalityConstants.dropzones_update_later_button = "dropzones_update_later_button";
                    LocalityConstants.screenshot_title = "screenshot_title";
                    LocalityConstants.switch_station_title = "switch_station_title";
                    LocalityConstants.target_menu_title = "target_menu_title";
                    LocalityConstants.subscription_discosure_title = "subscription_discosure_title";
                    LocalityConstants.subscription_discosure_text = "subscription_discosure_text";
                    LocalityConstants.switch_station_message = "switch_station_message";
                    LocalityConstants.delete_dropzone_title = "delete_dropzone_title";
                    LocalityConstants.switch_station_ok = "switch_station_ok";
                    LocalityConstants.delete_dropzone_message = "delete_dropzone_message";
                    LocalityConstants.register_option_title = "register_option_title";
                    LocalityConstants.register_option_text = "register_option_text";
                    LocalityConstants.unregister_option_title = "unregister_option_title";
                    LocalityConstants.unregister_option_text = "unregister_option_text";
                    LocalityConstants.registered_offer_title = "registered_offer_title";
                    LocalityConstants.registered_offer_enjoy_trial = "registered_offer_enjoy_trial";
                    LocalityConstants.wind_changed_title = "wind_changed_title";
                    LocalityConstants.wind_changed_message = "wind_changed_message";
                    LocalityConstants.register_option_thank_you_title = "register_option_thank_you_title";
                    LocalityConstants.register_option_trial_title = "register_option_trial_title";
                    LocalityConstants.new_target_title = "new_target_title";
                    LocalityConstants.edit_target_title = "edit_target_title";
                    LocalityConstants.direction_menu_title = "direction_menu_title";
                    LocalityConstants.make_corridor_title = "make_corridor_title";
                    LocalityConstants.reset_corridor_title = "reset_corridor_title";
                    LocalityConstants.pick_direction_title = "pick_direction_title";
                    LocalityConstants.lock_pattern_title = "lock_pattern_title";
                    LocalityConstants.lock_pattern_message = "lock_pattern_message";
                    LocalityConstants.register_label_title = "register_label_title";
                    LocalityConstants.purchase_options_title = "purchase_options_title";
                    LocalityConstants.dropzones_outdated_title = "dropzones_outdated_title";
                    LocalityConstants.dropzones_outdated_message = "dropzones_outdated_message";
                    LocalityConstants.purchase_options_month = "purchase_options_month";
                    LocalityConstants.purchase_options_life = "purchase_options_life";
                    LocalityConstants.big_picture_notification_title = "big_picture_notification_title";
                    LocalityConstants.big_picture_notification_description = "big_picture_notification_description";
                    LocalityConstants.big_picture_notification_question = "big_picture_notification_question";
                    LocalityConstants.product_ad_forecast_product_text = "product_ad_forecast_product_text";
                    LocalityConstants.product_ad_forecast_snippet = "product_ad_forecast_snippet";
                    LocalityConstants.product_gfs_forecast_product_text = "product_gfs_forecast_product_text";
                    LocalityConstants.product_gfs_forecast_snippet = "product_gfs_forecast_snippet";
                    LocalityConstants.product_gfs_windaloft_snippet = "product_gfs_windaloft_snippet";
                    LocalityConstants.no_target_warning = "no_target_warning";
                    LocalityConstants.pattern_warning = "pattern_warning";
                    LocalityConstants.about_read_blog = "about_read_blog";
                    LocalityConstants.about_watch_tutorials = "about_watch_tutorials";
                    LocalityConstants.about_like_facebook = "about_like_facebook";
                    LocalityConstants.about_play_rate = "about_play_rate";
                    LocalityConstants.about_store_rate = "about_store_rate";
                    LocalityConstants.about_email_us = "about_email_us";
                    LocalityConstants.tour_slide1_title = "tour_slide1_title";
                    LocalityConstants.tour_slide1_description = "tour_slide1_description";
                    LocalityConstants.tour_slide2_title = "tour_slide2_title";
                    LocalityConstants.tour_slide2_description = "tour_slide2_description";
                    LocalityConstants.tour_slide3_title = "tour_slide3_title";
                    LocalityConstants.tour_slide3_description = "tour_slide3_description";
                    LocalityConstants.tour_slide4_title = "tour_slide4_title";
                    LocalityConstants.tour_slide4_description = "tour_slide4_description";
                    LocalityConstants.tour_slide5_title = "tour_slide5_title";
                    LocalityConstants.tour_slide5_description = "tour_slide5_description";
                    LocalityConstants.corridor_slide1_title = "corridor_slide1_title";
                    LocalityConstants.corridor_slide1_description = "corridor_slide1_description";
                    LocalityConstants.corridor_slide2_title = "corridor_slide2_title";
                    LocalityConstants.corridor_slide2_description = "corridor_slide2_description";
                    LocalityConstants.corridor_slide3_title = "corridor_slide3_title";
                    LocalityConstants.corridor_slide3_description = "corridor_slide3_description";
                    LocalityConstants.crowd_slide1_title = "crowd_slide1_title";
                    LocalityConstants.crowd_slide1_description = "crowd_slide1_description";
                    LocalityConstants.crowd_slide2_title = "crowd_slide2_title";
                    LocalityConstants.crowd_slide2_description = "crowd_slide2_description";
                    LocalityConstants.register_option_enjoy_trial = "register_option_enjoy_trial";
                    LocalityConstants.register_option_used_trial = "register_option_used_trial";
                    LocalityConstants.product_buy_action_text = "product_buy_action_text";
                    LocalityConstants.product_see_action_text = "product_see_action_text";
                    LocalityConstants.product_ad_exit_product_text = "product_ad_exit_product_text";
                    LocalityConstants.product_ad_exit_snippet = "product_ad_exit_snippet";
                    LocalityConstants.product_ad_canopy_product_text = "product_ad_canopy_product_text";
                    LocalityConstants.product_ad_canopy_snippet = "product_ad_canopy_snippet";
                    LocalityConstants.pref_summary_speed_units = "pref_summary_speed_units";
                    LocalityConstants.pref_title_speed_units = "pref_title_speed_units";
                    LocalityConstants.pref_summary_distance_units = "pref_summary_distance_units";
                    LocalityConstants.pref_title_distance_units = "pref_title_distance_units";
                    LocalityConstants.pref_summary_altitude_units = "pref_summary_altitude_units";
                    LocalityConstants.pref_title_altitude_units = "pref_title_altitude_units";
                    LocalityConstants.pref_summary_temperature_units = "pref_summary_temperature_units";
                    LocalityConstants.pref_title_temperature_units = "pref_title_temperature_units";
                    LocalityConstants.pref_summary_time_units = "pref_summary_time_units";
                    LocalityConstants.pref_title_time_units = "pref_title_time_units";
                    LocalityConstants.register_button_title = "register_button_title";
                    LocalityConstants.delete_account_title = "delete_account_title";
                    LocalityConstants.register_now = "register_now";
                    LocalityConstants.rate_app_title = "rate_app_title";
                    LocalityConstants.no_data_warning = "no_data_warning";
                    LocalityConstants.canopy_student = "canopy_student";
                    LocalityConstants.canopy_sport = "canopy_sport";
                    LocalityConstants.canopy_pro = "canopy_pro";
                    LocalityConstants.open_by = "open_by";
                    LocalityConstants.pattern_no_strategy = "pattern_no_strategy";
                    LocalityConstants.pattern_stay_north = "pattern_stay_north";
                    LocalityConstants.pattern_stay_south = "pattern_stay_south";
                    LocalityConstants.pattern_stay_east = "pattern_stay_east";
                    LocalityConstants.pattern_stay_west = "pattern_stay_west";
                    LocalityConstants.pattern_always_left = "pattern_always_left";
                    LocalityConstants.pattern_always_right = "pattern_always_right";
                    LocalityConstants.pattern_title_auto = "pattern_title_auto";
                    LocalityConstants.pattern_title_left = "pattern_title_left";
                    LocalityConstants.pattern_title_right = "pattern_title_right";
                    LocalityConstants.menu_weather = "menu_weather";
                    LocalityConstants.menu_cutaway_finder = "menu_cutaway_finder";
                    LocalityConstants.menu_simulator = "menu_simulator";
                    LocalityConstants.menu_sattelite = "menu_sattelite";
                    LocalityConstants.menu_canopies = "menu_canopies";
                    LocalityConstants.menu_products = "menu_products";
                    LocalityConstants.menu_settings = "menu_settings";
                    LocalityConstants.menu_share = "menu_share";
                    LocalityConstants.menu_tour = "menu_tour";
                    LocalityConstants.menu_about = "menu_about";
                    LocalityConstants.screen_windaloft = "screen_windaloft";
                    LocalityConstants.screen_forecast = "screen_forecast";
                    LocalityConstants.issue_time = "issue_time";
                    LocalityConstants.download_time = "download_time";
                    LocalityConstants.forecast_unreliable = "forecast_unreliable";
                    LocalityConstants.distance = "distance";
                    LocalityConstants.dates_unreadable = "dates_unreadable";
                    LocalityConstants.station_no_upper_condition = "station_no_upper_condition";
                    LocalityConstants.station_no_upper_levels = "station_no_upper_levels";
                    LocalityConstants.valid = "valid";
                    LocalityConstants.to = "to";
                    LocalityConstants.surface_conditions_unreliable = "surface_conditions_unreliable";
                    LocalityConstants.observation_time = "observation_time";
                    LocalityConstants.no_upper_winds_stations = "no_upper_winds_stations";
                    LocalityConstants.no_current_conditions_stations = "no_current_conditions_stations";
                    LocalityConstants.no_forecast_stations = "no_forecast_stations";
                    LocalityConstants.no_openweather_stations = "no_openweather_stations";
                    LocalityConstants.upper_winds = "upper_winds";
                    LocalityConstants.current_conditions = "current_conditions";
                    LocalityConstants.current_conditions_for = "current_conditions_for";
                    LocalityConstants.forecast = "forecast";
                    LocalityConstants.forecast_for = "forecast_for";
                    LocalityConstants.openweather = "openweather";
                    LocalityConstants.surface_conditions_forecast_label = "surface_conditions_forecast_label";
                    LocalityConstants.surface_wind_forecast_label = "surface_wind_forecast_label";
                    LocalityConstants.surface_temp_forecast_label = "surface_temp_forecast_label";
                    LocalityConstants.surface_temp_chart_label = "surface_temp_chart_label";
                    LocalityConstants.surface_latitude_chart_label = "surface_latitude_chart_label";
                    LocalityConstants.additional_features = "additional_features";
                    LocalityConstants.dropzones_database = "dropzones_database";
                    LocalityConstants.update_dropzones_database = "update_dropzones_database";
                    LocalityConstants.update_dropzones = "update_dropzones";
                    LocalityConstants.no_target_selected = "no_target_selected";
                    LocalityConstants.target_label = "target_label";
                    LocalityConstants.stock_canopies = "stock_canopies";
                    LocalityConstants.custom_canopies = "custom_canopies";
                    LocalityConstants.canopy_airspeed = "canopy_airspeed";
                    LocalityConstants.canopy_glide_ratio = "canopy_glide_ratio";
                    LocalityConstants.canopy_name = "canopy_name";
                    LocalityConstants.edit = "edit";
                    LocalityConstants.delete = "delete";
                    LocalityConstants.registered_to = "registered_to";
                    LocalityConstants.wind_at = "wind_at";
                    LocalityConstants.long_press_to_move = "long_press_to_move";
                    LocalityConstants.calm = "calm";
                    LocalityConstants.surface = "surface";
                    LocalityConstants.base_to_final = "base_to_final";
                    LocalityConstants.final_l = "final_l";
                    LocalityConstants.downwind_to_base = "downwind_to_base";
                    LocalityConstants.base = "base";
                    LocalityConstants.start_downwind = "start_downwind";
                    LocalityConstants.downwind = "downwind";
                    LocalityConstants.restore_purchases = "restore_purchases";
                    LocalityConstants.restore_purchases_description = "restore_purchases_description";
                    LocalityConstants.restore_button = "restore_button";
                    LocalityConstants.about_read_blog_desc = "about_read_blog_desc";
                    LocalityConstants.about_watch_tutorials_desc = "about_watch_tutorials_desc";
                    LocalityConstants.about_like_facebook_desc = "about_like_facebook_desc";
                    LocalityConstants.about_play_rate_desc = "about_play_rate_desc";
                    LocalityConstants.about_email_us_desc = "about_email_us_desc";
                    LocalityConstants.privacy_policy_text = "privacy_policy_text";
                    LocalityConstants.privacy_policy_label = "privacy_policy_label";
                    LocalityConstants.terms_text = "terms_text";
                    LocalityConstants.terms_label = "terms_label";
                    LocalityConstants.yes = "yes";
                    LocalityConstants.no = "no";
                    LocalityConstants.open_settings_now = "open_settings_now";
                    LocalityConstants.select_cutaway_area_label = "select_cutaway_area_label";
                    LocalityConstants.cutaway_area_label = "cutaway_area_label";
                    LocalityConstants.canopy_landed_label = "canopy_landed_label";
                    LocalityConstants.strategy_always_adjust_upwind = "strategy_always_adjust_upwind";
                    LocalityConstants.strategy_consider_landing_corrider = "strategy_consider_landing_corrider";
                    LocalityConstants.subscription_info = "subscription_info";
                    LocalityConstants.stay_up_to_date = "stay_up_to_date";
                    LocalityConstants.subscription_info_title = "subscription_info_title";
                    LocalityConstants.subscription_info_description = "subscription_info_description";
                    LocalityConstants.purchase_expires = "purchase_expires";
                    LocalityConstants.purchase_gifted = "purchase_gifted";
                    LocalityConstants.trial_expires = "trial_expires";
                    LocalityConstants.product_purchased = "product_purchased";
                    LocalityConstants.product_subscribed = "product_subscribed";
                    LocalityConstants.press_to_select = "press_to_select";
                    LocalityConstants.press_to_change = "press_to_change";
                    LocalityConstants.crab_angle = "crab_angle";
                    LocalityConstants.upper_winds_for = "upper_winds_for";
                    LocalityConstants.surface_winds_for = "surface_winds_for";
                    LocalityConstants.view_from = "view_from";
                    LocalityConstants.billing_error_title = "billing_error_title";
                    LocalityConstants.billing_error_message = "billing_error_message";
                    LocalityConstants.successfully_restored_products = "successfully_restored_products";
                    LocalityConstants.unable_restore_products = "unable_restore_products";
                    return LocalityConstants;
                }());
                library.LocalityConstants = LocalityConstants;
                LocalityConstants["__class"] = "com.livewings.spotassist.library.LocalityConstants";
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var crossdata;
                (function (crossdata) {
                    var MapPoint = (function () {
                        function MapPoint(latitude, longitude) {
                            this.latitude = 0;
                            this.longitude = 0;
                            this.latitude = latitude;
                            this.longitude = longitude;
                        }
                        MapPoint.prototype.equals = function (obj) {
                            if (obj == null || !(obj != null && obj instanceof com.livewings.spotassist.library.crossdata.MapPoint)) {
                                return false;
                            }
                            var another = obj;
                            return this.latitude === another.latitude && this.longitude === another.longitude;
                        };
                        MapPoint.prototype.toString = function () {
                            return this.latitude + ", " + this.longitude;
                        };
                        return MapPoint;
                    }());
                    crossdata.MapPoint = MapPoint;
                    MapPoint["__class"] = "com.livewings.spotassist.library.crossdata.MapPoint";
                })(crossdata = library.crossdata || (library.crossdata = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var crossdata;
                (function (crossdata) {
                    var MapBounds = (function () {
                        function MapBounds(northeast, southwest) {
                            this.northeast = northeast;
                            this.southwest = southwest;
                        }
                        return MapBounds;
                    }());
                    crossdata.MapBounds = MapBounds;
                    MapBounds["__class"] = "com.livewings.spotassist.library.crossdata.MapBounds";
                })(crossdata = library.crossdata || (library.crossdata = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var crossdata;
                (function (crossdata) {
                    var PointF = (function () {
                        function PointF(x, y) {
                            this.x = 0;
                            this.y = 0;
                            this.x = x;
                            this.y = y;
                        }
                        return PointF;
                    }());
                    crossdata.PointF = PointF;
                    PointF["__class"] = "com.livewings.spotassist.library.crossdata.PointF";
                })(crossdata = library.crossdata || (library.crossdata = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var crossdata;
                (function (crossdata) {
                    var MapPolygon = (function () {
                        function MapPolygon() {
                            this.__strokeColor = 0;
                            this.__strokeWidth = 0;
                            this.__fillColor = 0;
                        }
                        MapPolygon.prototype.addAll = function (polygonPoints) {
                            this.polygonPoints = polygonPoints;
                            return this;
                        };
                        MapPolygon.prototype.getPolygonPoints = function () {
                            return this.polygonPoints;
                        };
                        MapPolygon.prototype.addHole = function (holePoints) {
                            this.holePoints = holePoints;
                            return this;
                        };
                        MapPolygon.prototype.getHolePoints = function () {
                            return this.holePoints;
                        };
                        MapPolygon.prototype.strokeColor = function (strokeColor) {
                            this.__strokeColor = strokeColor;
                            return this;
                        };
                        MapPolygon.prototype.getStrokeColor = function () {
                            return this.__strokeColor;
                        };
                        MapPolygon.prototype.strokeWidth = function (strokeWidth) {
                            this.__strokeWidth = strokeWidth;
                            return this;
                        };
                        MapPolygon.prototype.getStrokeWidth = function () {
                            return this.__strokeWidth;
                        };
                        MapPolygon.prototype.fillColor = function (fillColor) {
                            this.__fillColor = fillColor;
                            return this;
                        };
                        MapPolygon.prototype.getFillColor = function () {
                            return this.__fillColor;
                        };
                        MapPolygon.prototype.getPolygonObject = function () {
                            return this.polygonObject;
                        };
                        MapPolygon.prototype.setPolygonObject = function (polygonObject) {
                            this.polygonObject = polygonObject;
                        };
                        return MapPolygon;
                    }());
                    crossdata.MapPolygon = MapPolygon;
                    MapPolygon["__class"] = "com.livewings.spotassist.library.crossdata.MapPolygon";
                })(crossdata = library.crossdata || (library.crossdata = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                /**
                 * Created by AudreyKan on 4/7/17.
                 */
                var PatternData = (function () {
                    function PatternData() {
                        this.patternPointData = (new java.util.ArrayList());
                        this.patternFlyData = (new java.util.ArrayList());
                        this.canopyPatternBackwardFlying = false;
                    }
                    PatternData.prototype.isCanopyPatternBackwardFlying = function () {
                        return this.canopyPatternBackwardFlying;
                    };
                    PatternData.prototype.setCanopyPatternBackwardFlying = function (canopyPatternBackwardFlying) {
                        this.canopyPatternBackwardFlying = canopyPatternBackwardFlying;
                    };
                    PatternData.prototype.getPatternPointData = function () {
                        return this.patternPointData;
                    };
                    PatternData.prototype.setPatternPointData = function (patternPointData) {
                        this.patternPointData = patternPointData;
                    };
                    PatternData.prototype.getPatternFlyData = function () {
                        return this.patternFlyData;
                    };
                    PatternData.prototype.setPatternFlyData = function (patternFlyData) {
                        this.patternFlyData = patternFlyData;
                    };
                    return PatternData;
                }());
                library.PatternData = PatternData;
                PatternData["__class"] = "com.livewings.spotassist.library.PatternData";
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                /**
                 * Created by AudreyKan on 4/6/17.
                 */
                var WindArrowData = (function () {
                    function WindArrowData(location, flightUnit) {
                        this.location = location;
                        this.flightUnit = flightUnit;
                    }
                    WindArrowData.prototype.getLocation = function () {
                        return this.location;
                    };
                    WindArrowData.prototype.getFlightUnit = function () {
                        return this.flightUnit;
                    };
                    return WindArrowData;
                }());
                library.WindArrowData = WindArrowData;
                WindArrowData["__class"] = "com.livewings.spotassist.library.WindArrowData";
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                (function (SkyConditionType) {
                    SkyConditionType[SkyConditionType["SKC"] = 0] = "SKC";
                    SkyConditionType[SkyConditionType["NSC"] = 1] = "NSC";
                    SkyConditionType[SkyConditionType["CLR"] = 2] = "CLR";
                    SkyConditionType[SkyConditionType["CAVOK"] = 3] = "CAVOK";
                    SkyConditionType[SkyConditionType["FEW"] = 4] = "FEW";
                    SkyConditionType[SkyConditionType["SCT"] = 5] = "SCT";
                    SkyConditionType[SkyConditionType["BKN"] = 6] = "BKN";
                    SkyConditionType[SkyConditionType["OVC"] = 7] = "OVC";
                    SkyConditionType[SkyConditionType["OVX"] = 8] = "OVX";
                })(library.SkyConditionType || (library.SkyConditionType = {}));
                var SkyConditionType = library.SkyConditionType;
                var SkyConditionType_$WRAPPER = (function () {
                    function SkyConditionType_$WRAPPER(_$ordinal, _$name, type) {
                        this._$ordinal = _$ordinal;
                        this._$name = _$name;
                        this.__name = type;
                    }
                    SkyConditionType_$WRAPPER.prototype.getName = function () {
                        return this.__name;
                    };
                    SkyConditionType_$WRAPPER.prototype.name = function () { return this._$name; };
                    SkyConditionType_$WRAPPER.prototype.ordinal = function () { return this._$ordinal; };
                    return SkyConditionType_$WRAPPER;
                }());
                library.SkyConditionType_$WRAPPER = SkyConditionType_$WRAPPER;
                SkyConditionType["__class"] = "com.livewings.spotassist.library.SkyConditionType";
                SkyConditionType["__interfaces"] = ["java.lang.Comparable", "java.io.Serializable"];
                SkyConditionType["_$wrappers"] = [new SkyConditionType_$WRAPPER(0, "SKC", "Sky Clear"), new SkyConditionType_$WRAPPER(1, "NSC", "No Sig Cloud"), new SkyConditionType_$WRAPPER(2, "CLR", "Clear"), new SkyConditionType_$WRAPPER(3, "CAVOK", "CAVOK"), new SkyConditionType_$WRAPPER(4, "FEW", "Few"), new SkyConditionType_$WRAPPER(5, "SCT", "Scattered"), new SkyConditionType_$WRAPPER(6, "BKN", "Broken"), new SkyConditionType_$WRAPPER(7, "OVC", "Overcast"), new SkyConditionType_$WRAPPER(8, "OVX", "OVX")];
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var gfs;
                (function (gfs) {
                    var GfsConverter = (function () {
                        function GfsConverter(gfsHelper) {
                            this.gfsHelper = gfsHelper;
                        }
                        GfsConverter.prototype.convertGfsToStations = function (gfsPoints) {
                            var stations = (new java.util.ArrayList());
                            for (var index121 = 0; index121 < gfsPoints.length; index121++) {
                                var gfsPoint = gfsPoints[index121];
                                {
                                    var metars = (new java.util.ArrayList());
                                    var windaloft = null;
                                    var taf = null;
                                    try {
                                        metars = this.extractMetar(gfsPoint);
                                        windaloft = this.extractWindaloft(gfsPoint);
                                        taf = this.extractTaf(gfsPoint);
                                    }
                                    catch (ex) {
                                        if (this.gfsHelper.getLogReporter() != null) {
                                            var gfsException = this.gfsHelper.createException("Unable to parse GFS data.", ex);
                                            this.gfsHelper.getLogReporter().logNonFatalException(gfsException);
                                        }
                                    }
                                    ;
                                    var location_1 = gfsPoint.getLocation();
                                    var lat = location_1.getCoordinates().get(1);
                                    var lon = location_1.getCoordinates().get(0);
                                    var stationTypes = (new java.util.ArrayList());
                                    if (metars.size() > 0) {
                                        stationTypes.add(com.livewings.spotassist.library.json.StationType.METAR);
                                    }
                                    if (windaloft != null) {
                                        stationTypes.add(com.livewings.spotassist.library.json.StationType.windsaloft);
                                    }
                                    if (taf != null) {
                                        stationTypes.add(com.livewings.spotassist.library.json.StationType.TAF);
                                    }
                                    var station_id = gfsPoint.getLocation().getIdent();
                                    var station_name = "GFS: " + gfsPoint.getLocation().getSite();
                                    var site = "GFS: " + gfsPoint.getLocation().getSite();
                                    var station = this.gfsHelper.createStation(station_id, station_name, site, lat, lon, stationTypes, metars, taf, windaloft);
                                    station.setGfs(true);
                                    stations.add(station);
                                }
                            }
                            return stations.toArray(new Array(0));
                        };
                        GfsConverter.prototype.extractMetar = function (gfsPoint) {
                            var metars = (new java.util.ArrayList());
                            if (gfsPoint.getResults().length > 0) {
                                var firstResult = gfsPoint.getResults()[0];
                                var surface = firstResult.getSurface();
                                var clouds = firstResult.getClouds();
                                if (surface != null) {
                                    try {
                                        var temp_c = (surface.getTemp_c() | 0);
                                        var dewpoint_c = -1;
                                        var altim_in_hg = -1;
                                        var wind_dir_degrees = surface.getWind_direction_degree() == null ? javaemul.internal.IntegerHelper.MIN_VALUE : surface.getWind_direction_degree();
                                        var wind_speed_kt = surface.getWind_speed_ms() == null ? javaemul.internal.DoubleHelper.MIN_VALUE : (surface.getWind_speed_ms() * com.livewings.spotassist.library.math.Conversion.METERSPERSEC_TO_KNOTS);
                                        var wind_gust_kt = surface.getWind_gust_ms() == null ? javaemul.internal.DoubleHelper.MIN_VALUE : (surface.getWind_gust_ms() * com.livewings.spotassist.library.math.Conversion.METERSPERSEC_TO_KNOTS);
                                        var ws_string = null;
                                        var visibility_statute_mi = -1;
                                        var observation_time = this.gfsHelper.getDatetimeParser().stringForDatetime(firstResult.getDatetime().getTime());
                                        var sky_conditions = this.extractSkyConditions(clouds);
                                        var metar = new com.livewings.spotassist.library.json.Metar(temp_c, dewpoint_c, altim_in_hg, wind_dir_degrees, wind_speed_kt, wind_gust_kt, ws_string, visibility_statute_mi, observation_time, sky_conditions);
                                        metars.add(metar);
                                    }
                                    catch (ex) {
                                        if (this.gfsHelper.getLogReporter() != null) {
                                            this.gfsHelper.getLogReporter().logJsonObject(surface);
                                        }
                                        throw ex;
                                    }
                                    ;
                                }
                            }
                            return metars;
                        };
                        GfsConverter.prototype.extractSkyConditions = function (clouds) {
                            var sky_conditions = (new java.util.ArrayList());
                            if (clouds == null) {
                                return sky_conditions;
                            }
                            for (var index122 = 0; index122 < clouds.length; index122++) {
                                var cloudLevel = clouds[index122];
                                {
                                    try {
                                        var skyConditionType = GfsConverter.getSkyConditionType(cloudLevel.getPercent());
                                        var sky_cover = com.livewings.spotassist.library.SkyConditionType["_$wrappers"][skyConditionType].toString();
                                        var sky_cover_human = com.livewings.spotassist.library.SkyConditionType["_$wrappers"][skyConditionType].getName();
                                        var cloud_base_ft_agl = (new Number(cloudLevel.getHeight_m() * com.livewings.spotassist.library.math.Conversion.METERS_TO_FEET) | 0);
                                        var skyCondition = new com.livewings.spotassist.library.json.SkyCondition(sky_cover, sky_cover_human, cloud_base_ft_agl);
                                        sky_conditions.add(skyCondition);
                                    }
                                    catch (ex) {
                                        if (this.gfsHelper.getLogReporter() != null) {
                                            this.gfsHelper.getLogReporter().logJsonObject(cloudLevel);
                                        }
                                        throw ex;
                                    }
                                    ;
                                }
                            }
                            return sky_conditions;
                        };
                        GfsConverter.prototype.extractRawinsondes = function (gfsPoint) {
                            var rawinsondes = (new java.util.ArrayList());
                            if (gfsPoint.getResults().length > 0) {
                                var firstResult = gfsPoint.getResults()[0];
                                var windsaloft = firstResult.getWindsaloft();
                                if (windsaloft.length > 0) {
                                    var sondeLevels = (new java.util.ArrayList());
                                    for (var index123 = 0; index123 < windsaloft.length; index123++) {
                                        var windaloftLevel = windsaloft[index123];
                                        {
                                            var heightM = (windaloftLevel.getHeight_m() | 0);
                                            var directionFrom = windaloftLevel.getWind_direction_degree();
                                            var speedMs = windaloftLevel.getWind_speed_ms();
                                            var tempC = (windaloftLevel.getTemp_c() | 0);
                                            var sondeLevel = new com.livewings.spotassist.library.json.SondeLevel(heightM, directionFrom, speedMs, tempC);
                                            sondeLevels.add(sondeLevel);
                                        }
                                    }
                                    var valid_from = this.gfsHelper.getDatetimeParser().stringForDatetime(firstResult.getDatetime().getTime());
                                    var rawinsonde = new com.livewings.spotassist.library.json.Rawinsonde(valid_from, sondeLevels);
                                    rawinsondes.add(rawinsonde);
                                }
                            }
                            return rawinsondes;
                        };
                        GfsConverter.prototype.extractTaf = function (gfsPoint) {
                            var taf = null;
                            var issue_time = null;
                            var forecasts = (new java.util.ArrayList());
                            {
                                var array125 = gfsPoint.getResults();
                                for (var index124 = 0; index124 < array125.length; index124++) {
                                    var result = array125[index124];
                                    {
                                        var surface = result.getSurface();
                                        if (surface != null) {
                                            if (issue_time == null) {
                                                issue_time = this.gfsHelper.getDatetimeParser().stringForDatetime(result.getDatetime().getTime());
                                            }
                                            try {
                                                var fcst_time_from = this.gfsHelper.getDatetimeParser().stringForDatetime(result.getDatetime().getTime());
                                                var validToTime = result.getDatetime().getTime() + 3 * 60 * 60 * 1000;
                                                var fcst_time_to = this.gfsHelper.getDatetimeParser().stringForDatetime(validToTime);
                                                var time_becoming = null;
                                                var change_indicator = null;
                                                var temp_c = surface.getTemp_c();
                                                var wind_dir_degrees = surface.getWind_direction_degree() == null ? javaemul.internal.IntegerHelper.MIN_VALUE : surface.getWind_direction_degree();
                                                var wind_speed_kt = surface.getWind_speed_ms() == null ? javaemul.internal.DoubleHelper.MIN_VALUE : (surface.getWind_speed_ms() * com.livewings.spotassist.library.math.Conversion.METERSPERSEC_TO_KNOTS);
                                                var visibility_statute_mi = javaemul.internal.DoubleHelper.MIN_VALUE;
                                                var wind_gust_kt = surface.getWind_gust_ms() == null ? javaemul.internal.DoubleHelper.MIN_VALUE : (surface.getWind_gust_ms() * com.livewings.spotassist.library.math.Conversion.METERSPERSEC_TO_KNOTS);
                                                var wx_string = null;
                                                var probability = javaemul.internal.IntegerHelper.MIN_VALUE;
                                                var sky_conditions = this.extractSkyConditions(result.getClouds());
                                                var forecast = new com.livewings.spotassist.library.json.Forecast(fcst_time_from, fcst_time_to, time_becoming, change_indicator, wind_dir_degrees, wind_speed_kt, visibility_statute_mi, wind_gust_kt, wx_string, probability, temp_c, sky_conditions);
                                                forecasts.add(forecast);
                                            }
                                            catch (ex) {
                                                if (this.gfsHelper.getLogReporter() != null) {
                                                    this.gfsHelper.getLogReporter().logJsonObject(surface);
                                                }
                                                throw ex;
                                            }
                                            ;
                                        }
                                    }
                                }
                            }
                            if (forecasts.size() > 0) {
                                taf = new com.livewings.spotassist.library.json.Taf(issue_time, forecasts);
                            }
                            return taf;
                        };
                        GfsConverter.prototype.extractWindaloft = function (gfsPoint) {
                            var windaloft = null;
                            if (gfsPoint.getResults().length > 0) {
                                var firstResult = gfsPoint.getResults()[0];
                                var windsaloft = firstResult.getWindsaloft();
                                if (windsaloft.length > 0) {
                                    var flightlevels = (new java.util.ArrayList());
                                    for (var index126 = 0; index126 < windsaloft.length; index126++) {
                                        var windaloftLevel = windsaloft[index126];
                                        {
                                            try {
                                                var heightM = (windaloftLevel.getHeight_m() | 0);
                                                var directionFrom = windaloftLevel.getWind_direction_degree() == null ? -1 : windaloftLevel.getWind_direction_degree();
                                                var speedMs = windaloftLevel.getWind_speed_ms() == null ? javaemul.internal.DoubleHelper.valueOf(-1) : windaloftLevel.getWind_speed_ms();
                                                var tempC = (windaloftLevel.getTemp_c() | 0);
                                                var sondeLevel = new com.livewings.spotassist.library.json.SondeLevel(heightM, directionFrom, speedMs, tempC);
                                                var flightlevel = (((windaloftLevel.getHeight_m() * com.livewings.spotassist.library.math.Conversion.METERS_TO_FEET) | 0) / 1000 | 0);
                                                if (flightlevel === 0) {
                                                    continue;
                                                }
                                                var speed = speedMs * com.livewings.spotassist.library.math.Conversion.METERSPERSEC_TO_KNOTS;
                                                var flightLevel = new com.livewings.spotassist.library.json.FlightLevel(flightlevel, directionFrom, speed, tempC);
                                                flightlevels.add(flightLevel);
                                            }
                                            catch (ex) {
                                                if (this.gfsHelper.getLogReporter() != null) {
                                                    this.gfsHelper.getLogReporter().logJsonObject(windaloftLevel);
                                                }
                                                throw ex;
                                            }
                                            ;
                                        }
                                    }
                                    var valid_from = this.gfsHelper.getDatetimeParser().stringForDatetime(firstResult.getDatetime().getTime());
                                    var validToTime = firstResult.getDatetime().getTime() + 3 * 60 * 60 * 1000;
                                    var valid_to = this.gfsHelper.getDatetimeParser().stringForDatetime(validToTime);
                                    windaloft = new com.livewings.spotassist.library.json.Windaloft(valid_from, valid_to, flightlevels);
                                }
                            }
                            return windaloft;
                        };
                        GfsConverter.getSkyConditionType = function (percentCover) {
                            if (percentCover < 5) {
                                return com.livewings.spotassist.library.SkyConditionType.CLR;
                            }
                            else if (percentCover < 20) {
                                return com.livewings.spotassist.library.SkyConditionType.FEW;
                            }
                            else if (percentCover < 50) {
                                return com.livewings.spotassist.library.SkyConditionType.SCT;
                            }
                            else if (percentCover < 80) {
                                return com.livewings.spotassist.library.SkyConditionType.BKN;
                            }
                            else {
                                return com.livewings.spotassist.library.SkyConditionType.OVC;
                            }
                        };
                        return GfsConverter;
                    }());
                    gfs.GfsConverter = GfsConverter;
                    GfsConverter["__class"] = "com.livewings.spotassist.library.gfs.GfsConverter";
                })(gfs = library.gfs || (library.gfs = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var Conversion = (function () {
                        function Conversion() {
                        }
                        Conversion.F_TO_C = function (f) {
                            return ((5 / 9 | 0)) * (f - 32);
                        };
                        Conversion.C_TO_F = function (c) {
                            return c * 9 / 5 + 32;
                        };
                        Conversion.C_TO_K = function (c) {
                            return c * 273.15;
                        };
                        Conversion.CELCIUS = "\u2103";
                        Conversion.DIRECTION = "\u00b0";
                        Conversion.FARENHEIGHT = "\u2109";
                        Conversion.MPH_TO_METERSPERSEC = 0.44704;
                        Conversion.KMH_TO_METERSPERSEC = 0.277778;
                        Conversion.KNOTS_TO_METERSPERSEC = 0.51444444444;
                        Conversion.METERSPERSEC_TO_KNOTS = 1.94384449;
                        Conversion.METERSPERSEC_TO_MPH = 2.2369362920544;
                        Conversion.METERSPERSEC_TO_KMH = 3.6;
                        Conversion.KNOTS_TO_KMH = 1.852;
                        Conversion.KNOTS_TO_MPH = 1.15077945;
                        Conversion.MPH_TO_KNOTS = 0.868976;
                        Conversion.KMH_TO_KNOTS = 0.539957;
                        Conversion.FEET_TO_METERS = 0.3048;
                        Conversion.METERS_TO_FEET = 3.28084;
                        Conversion.METERS_TO_MILES = 6.21371E-4;
                        Conversion.MILES_TO_METERS = 1609.34;
                        Conversion.IN_TO_MB = 33.8638815;
                        return Conversion;
                    }());
                    math.Conversion = Conversion;
                    Conversion["__class"] = "com.livewings.spotassist.library.math.Conversion";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var WindUnit = (function () {
                        function WindUnit(windSpeedMs, windAngleFrom) {
                            this.windSpeedMs = 0;
                            this.windGustSpeedKt = 0;
                            this.windAngleFrom = 0;
                            this.windSpeedMs = windSpeedMs;
                            this.windAngleFrom = windAngleFrom;
                        }
                        WindUnit.prototype.getWindSpeedMs = function () {
                            return this.windSpeedMs;
                        };
                        WindUnit.prototype.setWindSpeedMs = function (windSpeedMs) {
                            this.windSpeedMs = windSpeedMs;
                        };
                        WindUnit.prototype.getWindGustSpeedKt = function () {
                            return this.windGustSpeedKt;
                        };
                        WindUnit.prototype.getWindGustSpeedMs = function () {
                            return this.windGustSpeedKt * com.livewings.spotassist.library.math.Conversion.KNOTS_TO_METERSPERSEC;
                        };
                        WindUnit.prototype.setWindGustSpeedKt = function (windGustSpeedKt) {
                            this.windGustSpeedKt = windGustSpeedKt;
                        };
                        WindUnit.prototype.getWindAngleFrom = function () {
                            return this.windAngleFrom;
                        };
                        WindUnit.prototype.toString = function () {
                            return "Speed ms:" + this.getWindSpeedMs() + (this.getWindGustSpeedKt() > 0 ? (" Gust ms:" + this.getWindGustSpeedKt()) : "") + " From:" + this.getWindAngleFrom();
                        };
                        return WindUnit;
                    }());
                    math.WindUnit = WindUnit;
                    WindUnit["__class"] = "com.livewings.spotassist.library.math.WindUnit";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var ForecastPhenomenaData = (function () {
                        function ForecastPhenomenaData(code, description) {
                            this.code = 0;
                            this.code = code;
                            this.description = description;
                        }
                        ForecastPhenomenaData.prototype.getCode = function () {
                            return this.code;
                        };
                        ForecastPhenomenaData.prototype.getDescription = function () {
                            return this.description;
                        };
                        return ForecastPhenomenaData;
                    }());
                    math.ForecastPhenomenaData = ForecastPhenomenaData;
                    ForecastPhenomenaData["__class"] = "com.livewings.spotassist.library.math.ForecastPhenomenaData";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var FlightUnit = (function () {
                        function FlightUnit(topAltitudeFt, bottomAltitudeFt, windUnit) {
                            this.topAltitudeFt = 0;
                            this.bottomAltitudeFt = 0;
                            this.topAltitudeFt = topAltitudeFt;
                            this.bottomAltitudeFt = bottomAltitudeFt;
                            this.windUnit = windUnit;
                        }
                        FlightUnit.prototype.getTopAltitudeFt = function () {
                            return this.topAltitudeFt;
                        };
                        FlightUnit.prototype.getBottomAltitudeFt = function () {
                            return this.bottomAltitudeFt;
                        };
                        FlightUnit.prototype.getWindUnit = function () {
                            return this.windUnit;
                        };
                        FlightUnit.prototype.toString = function () {
                            return "Top:" + this.getTopAltitudeFt() + " Bottom:" + this.getBottomAltitudeFt();
                        };
                        return FlightUnit;
                    }());
                    math.FlightUnit = FlightUnit;
                    FlightUnit["__class"] = "com.livewings.spotassist.library.math.FlightUnit";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var FlightUnitComparator = (function () {
                        function FlightUnitComparator() {
                        }
                        FlightUnitComparator.prototype.compare = function (a1, a2) {
                            if (a1.getTopAltitudeFt() > a2.getTopAltitudeFt()) {
                                return 1;
                            }
                            else if (a1.getTopAltitudeFt() < a2.getTopAltitudeFt()) {
                                return -1;
                            }
                            else {
                                return 0;
                            }
                        };
                        return FlightUnitComparator;
                    }());
                    math.FlightUnitComparator = FlightUnitComparator;
                    FlightUnitComparator["__class"] = "com.livewings.spotassist.library.math.FlightUnitComparator";
                    FlightUnitComparator["__interfaces"] = ["java.util.Comparator"];
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var WeatherValidator = (function () {
                        function WeatherValidator() {
                        }
                        WeatherValidator.getValidatedWind = function (windaloft, metar, flightLevel, drawingWarnings) {
                            if (flightLevel === 1) {
                                return WeatherValidator.getValidatedMetar(metar, drawingWarnings);
                            }
                            else {
                                return WeatherValidator.getValidatedWindaloft(windaloft, flightLevel, drawingWarnings);
                            }
                        };
                        WeatherValidator.getValidatedWindaloft = function (windaloft, flightLevel, drawingWarnings) {
                            var windSpeedMs = 0;
                            var windAngleFrom = 0;
                            if (windaloft == null) {
                                drawingWarnings.add(com.livewings.spotassist.library.math.WeatherDisplayWarning.NO_WINDALOFT);
                            }
                            else {
                                var flightLevelNK = windaloft.getFlightLevel(flightLevel);
                                if (flightLevelNK == null) {
                                    drawingWarnings.add(com.livewings.spotassist.library.math.WeatherDisplayWarning.NO_WINDALOFT_LEVEL);
                                }
                                else {
                                    if (flightLevelNK.getSpeed() >= 0) {
                                        windSpeedMs = flightLevelNK.getSpeedMs();
                                    }
                                    if (flightLevelNK.getDirection() >= 0) {
                                        windAngleFrom = flightLevelNK.getDirection();
                                    }
                                }
                            }
                            return new com.livewings.spotassist.library.math.WindUnit(windSpeedMs, windAngleFrom);
                        };
                        WeatherValidator.getValidatedMetar = function (metar, drawingWarnings) {
                            var windSpeedMs = 0;
                            var windGustSpeedKt = 0;
                            var windAngleFrom = 0;
                            if (metar == null) {
                                drawingWarnings.add(com.livewings.spotassist.library.math.WeatherDisplayWarning.NO_METAR);
                            }
                            else {
                                if (metar.getWind_speed_kt() >= 0) {
                                    windSpeedMs = metar.getWindSpeedMs();
                                }
                                if (metar.getWind_gust_kt() >= 0) {
                                    windGustSpeedKt = metar.getWind_gust_kt();
                                }
                                if (metar.getWind_dir_degrees() >= 0) {
                                    windAngleFrom = metar.getWind_dir_degrees();
                                }
                            }
                            var windUnit = new com.livewings.spotassist.library.math.WindUnit(windSpeedMs, windAngleFrom);
                            windUnit.setWindGustSpeedKt(windGustSpeedKt);
                            return windUnit;
                        };
                        return WeatherValidator;
                    }());
                    math.WeatherValidator = WeatherValidator;
                    WeatherValidator["__class"] = "com.livewings.spotassist.library.math.WeatherValidator";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    (function (WeatherDisplayWarning) {
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_METAR_STATION"] = 0] = "NO_METAR_STATION";
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_METAR"] = 1] = "NO_METAR";
                        WeatherDisplayWarning[WeatherDisplayWarning["STALE_METAR"] = 2] = "STALE_METAR";
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_TAF_STATION"] = 3] = "NO_TAF_STATION";
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_TAF"] = 4] = "NO_TAF";
                        WeatherDisplayWarning[WeatherDisplayWarning["STALE_TAF"] = 5] = "STALE_TAF";
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_OPENWEATHER_STATION"] = 6] = "NO_OPENWEATHER_STATION";
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_OPENWEATHER"] = 7] = "NO_OPENWEATHER";
                        WeatherDisplayWarning[WeatherDisplayWarning["STALE_OPENWEATHER"] = 8] = "STALE_OPENWEATHER";
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_WINDALOFT_STATION"] = 9] = "NO_WINDALOFT_STATION";
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_WINDALOFT"] = 10] = "NO_WINDALOFT";
                        WeatherDisplayWarning[WeatherDisplayWarning["NO_WINDALOFT_LEVEL"] = 11] = "NO_WINDALOFT_LEVEL";
                        WeatherDisplayWarning[WeatherDisplayWarning["STALE_WINDALOFT"] = 12] = "STALE_WINDALOFT";
                    })(math.WeatherDisplayWarning || (math.WeatherDisplayWarning = {}));
                    var WeatherDisplayWarning = math.WeatherDisplayWarning;
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var SkydiveMath = (function () {
                        function SkydiveMath() {
                        }
                        SkydiveMath.TAG_$LI$ = function () { if (SkydiveMath.TAG == null)
                            SkydiveMath.TAG = (function (c) { return c["__class"] ? c["__class"].substring(c["__class"].lastIndexOf('.') + 1) : c.name.substring(c.name.lastIndexOf('.') + 1); })(SkydiveMath); return SkydiveMath.TAG; };
                        ;
                        SkydiveMath.getDirectionReciprocal = function (direction) {
                            var reciprocal = direction < 180 ? (180 + direction) : (direction - 180);
                            return reciprocal;
                        };
                        SkydiveMath.calcFreefallDistance$double$double = function (initialSpeedX, initialSpeedY) {
                            return SkydiveMath.calcFreefallDistance(initialSpeedX, initialSpeedY, SkydiveMath.BELLY_B, false);
                        };
                        SkydiveMath.calcFreefallDistance = function (initialSpeedX, initialSpeedY, b, log) {
                            if (((typeof initialSpeedX === 'number') || initialSpeedX === null) && ((typeof initialSpeedY === 'number') || initialSpeedY === null) && ((typeof b === 'number') || b === null) && ((typeof log === 'boolean') || log === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                return (function () {
                                    var speedX = initialSpeedX;
                                    var speedY = initialSpeedY;
                                    var linearSpeed = SkydiveMath.calcLinearSpeed(speedX, speedY);
                                    var distX = 0;
                                    var distY = 0;
                                    var deltaT = 1;
                                    for (var t = 1; t < 70; t += deltaT) {
                                        var nextSpeedX = speedX - b * speedX * linearSpeed * deltaT;
                                        var nextSpeedY = speedY - SkydiveMath.g * deltaT - b * speedY * linearSpeed * deltaT;
                                        distX = distX + 0.5 * (speedX + nextSpeedX) * deltaT;
                                        distY = distY + 0.5 * (speedY + nextSpeedY) * deltaT;
                                        speedX = nextSpeedX;
                                        speedY = nextSpeedY;
                                        linearSpeed = SkydiveMath.calcLinearSpeed(speedX, speedY);
                                    }
                                    return distX;
                                })();
                            }
                            else if (((typeof initialSpeedX === 'number') || initialSpeedX === null) && ((typeof initialSpeedY === 'number') || initialSpeedY === null) && b === undefined && log === undefined) {
                                return com.livewings.spotassist.library.math.SkydiveMath.calcFreefallDistance$double$double(initialSpeedX, initialSpeedY);
                            }
                            else
                                throw new Error('invalid overload');
                        };
                        SkydiveMath.calcLinearSpeed = function (speedX, speedY) {
                            var linearSpeed = Math.sqrt(speedX * speedX + speedY * speedY);
                            return linearSpeed;
                        };
                        SkydiveMath.calcCrossSpeedX = function (windAngleFrom, windSpeed) {
                            var windSpeedX = windSpeed * Math.sin(/* toRadians */ (function (x) { return x * Math.PI / 180; })(windAngleFrom));
                            return windSpeedX;
                        };
                        SkydiveMath.calcCrossSpeedY = function (windAngleFrom, windSpeedMs) {
                            var windSpeedY = windSpeedMs * Math.cos(/* toRadians */ (function (x) { return x * Math.PI / 180; })(windAngleFrom));
                            return windSpeedY;
                        };
                        SkydiveMath.calcWindDrift = function (flightTimeSec, windAngleFrom, windSpeedMs) {
                            var windSpeedX = SkydiveMath.calcCrossSpeedX(windAngleFrom, windSpeedMs);
                            var windSpeedY = SkydiveMath.calcCrossSpeedY(windAngleFrom, windSpeedMs);
                            var driftX = (windSpeedX * flightTimeSec);
                            var driftY = (windSpeedY * flightTimeSec);
                            return new com.livewings.spotassist.library.crossdata.PointF(driftX, driftY);
                        };
                        SkydiveMath.calcCanopyWindDrift = function (canopy, windAngleFrom, windSpeedMs, altitudeTopFt, altitudeBottomFt) {
                            var canopyFlightTimeSec = SkydiveMath.getCanopyFlightTimeSec(canopy, altitudeTopFt, altitudeBottomFt);
                            return SkydiveMath.calcWindDrift(canopyFlightTimeSec, windAngleFrom, windSpeedMs);
                        };
                        SkydiveMath.calcBearing = function (p1, p2) {
                            var degToRad = Math.PI / 180.0;
                            var phi1 = p1.latitude * degToRad;
                            var phi2 = p2.latitude * degToRad;
                            var lam1 = p1.longitude * degToRad;
                            var lam2 = p2.longitude * degToRad;
                            return Math.atan2(Math.sin(lam2 - lam1) * Math.cos(phi2), Math.cos(phi1) * Math.sin(phi2) - Math.sin(phi1) * Math.cos(phi2) * Math.cos(lam2 - lam1)) * 180 / Math.PI;
                        };
                        SkydiveMath.getAirColumnWinds$int$int$com_livewings_spotassist_library_json_Rawinsonde = function (topAltitude, bottomAltitude, rawinsonde) {
                            var flightUnits = (new java.util.ArrayList(0));
                            var currentAltitude = bottomAltitude;
                            for (var index127 = rawinsonde.getSondeLevels().iterator(); index127.hasNext();) {
                                var sondeLevel = index127.next();
                                {
                                    var flightAltitude = ((sondeLevel.getHeightM() * com.livewings.spotassist.library.math.Conversion.METERS_TO_FEET) | 0);
                                    var levelBottomAltitude = currentAltitude;
                                    var levelTopAltitude = Math.min(flightAltitude, topAltitude);
                                    if (Math.abs(levelTopAltitude - levelBottomAltitude) < 100) {
                                        continue;
                                    }
                                    var windUnit = new com.livewings.spotassist.library.math.WindUnit(sondeLevel.getSpeedMs(), sondeLevel.getDirectionFrom());
                                    flightUnits.add(new com.livewings.spotassist.library.math.FlightUnit(levelTopAltitude, levelBottomAltitude, windUnit));
                                    currentAltitude = flightAltitude;
                                    if (currentAltitude >= topAltitude) {
                                        break;
                                    }
                                }
                            }
                            return flightUnits;
                        };
                        SkydiveMath.getAirColumnWinds = function (topAltitude, bottomAltitude, windaloft, metar, drawingWarnings) {
                            if (((typeof topAltitude === 'number') || topAltitude === null) && ((typeof bottomAltitude === 'number') || bottomAltitude === null) && ((windaloft != null && windaloft instanceof com.livewings.spotassist.library.json.Windaloft) || windaloft === null) && ((metar != null && metar instanceof com.livewings.spotassist.library.json.Metar) || metar === null) && ((drawingWarnings != null && (drawingWarnings["__interfaces"] != null && drawingWarnings["__interfaces"].indexOf("java.util.Set") >= 0 || drawingWarnings.constructor != null && drawingWarnings.constructor["__interfaces"] != null && drawingWarnings.constructor["__interfaces"].indexOf("java.util.Set") >= 0)) || drawingWarnings === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                return (function () {
                                    var flightUnits = (new java.util.ArrayList(0));
                                    var currentAltitude = bottomAltitude;
                                    var availableLevels = (new java.util.ArrayList());
                                    availableLevels.add(1);
                                    if (windaloft != null && windaloft.getFlightLevels() != null) {
                                        for (var index128 = windaloft.getFlightLevels().iterator(); index128.hasNext();) {
                                            var level = index128.next();
                                            {
                                                if (!availableLevels.contains(level.getFlightlevel())) {
                                                    availableLevels.add(level.getFlightlevel());
                                                }
                                            }
                                        }
                                    }
                                    var sortedAvailableLevels = availableLevels.toArray(new Array(0));
                                    java.util.Arrays.sort(sortedAvailableLevels);
                                    for (var i = 0; i < sortedAvailableLevels.length; i++) {
                                        var flightLevel = sortedAvailableLevels[i];
                                        var flightAltitude = flightLevel * 1000;
                                        if (currentAltitude < flightAltitude) {
                                            var windUnit = com.livewings.spotassist.library.math.WeatherValidator.getValidatedWind(windaloft, metar, flightLevel, drawingWarnings);
                                            var levelBottomAltitude = currentAltitude;
                                            var levelTopAltitude = Math.min(flightAltitude, topAltitude);
                                            flightUnits.add(new com.livewings.spotassist.library.math.FlightUnit(levelTopAltitude, levelBottomAltitude, windUnit));
                                            currentAltitude = flightAltitude;
                                            if (currentAltitude >= topAltitude) {
                                                break;
                                            }
                                        }
                                    }
                                    return flightUnits;
                                })();
                            }
                            else if (((typeof topAltitude === 'number') || topAltitude === null) && ((typeof bottomAltitude === 'number') || bottomAltitude === null) && ((windaloft != null && windaloft instanceof com.livewings.spotassist.library.json.Rawinsonde) || windaloft === null) && metar === undefined && drawingWarnings === undefined) {
                                return com.livewings.spotassist.library.math.SkydiveMath.getAirColumnWinds$int$int$com_livewings_spotassist_library_json_Rawinsonde(topAltitude, bottomAltitude, windaloft);
                            }
                            else
                                throw new Error('invalid overload');
                        };
                        SkydiveMath.calcCanopyDistance = function (canopy, canopyFlyingDirection, fromAltitude, toAltitude, flightUnits, drawingWarnings) {
                            if (((canopy != null && (canopy["__interfaces"] != null && canopy["__interfaces"].indexOf("com.livewings.spotassist.library.json.ICanopy") >= 0 || canopy.constructor != null && canopy.constructor["__interfaces"] != null && canopy.constructor["__interfaces"].indexOf("com.livewings.spotassist.library.json.ICanopy") >= 0)) || canopy === null) && ((typeof canopyFlyingDirection === 'number') || canopyFlyingDirection === null) && ((typeof fromAltitude === 'number') || fromAltitude === null) && ((typeof toAltitude === 'number') || toAltitude === null) && ((flightUnits != null && (flightUnits["__interfaces"] != null && flightUnits["__interfaces"].indexOf("java.util.List") >= 0 || flightUnits.constructor != null && flightUnits.constructor["__interfaces"] != null && flightUnits.constructor["__interfaces"].indexOf("java.util.List") >= 0)) || flightUnits === null) && ((drawingWarnings != null && (drawingWarnings["__interfaces"] != null && drawingWarnings["__interfaces"].indexOf("java.util.Set") >= 0 || drawingWarnings.constructor != null && drawingWarnings.constructor["__interfaces"] != null && drawingWarnings.constructor["__interfaces"].indexOf("java.util.Set") >= 0)) || drawingWarnings === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                return (function () {
                                    var canopyDistance = new com.livewings.spotassist.library.crossdata.PointF(0.0, 0.0);
                                    for (var index129 = flightUnits.iterator(); index129.hasNext();) {
                                        var flightUnit = index129.next();
                                        {
                                            var canopyFlightTimeSec = SkydiveMath.getCanopyFlightTimeSec(canopy, flightUnit.getTopAltitudeFt(), flightUnit.getBottomAltitudeFt());
                                            var levelCanopyDistance = SkydiveMath.calcCanopyDistance(canopy, canopyFlyingDirection, canopyFlightTimeSec, flightUnit.getWindUnit().getWindSpeedMs(), flightUnit.getWindUnit().getWindAngleFrom());
                                            canopyDistance = new com.livewings.spotassist.library.crossdata.PointF(canopyDistance.x + levelCanopyDistance.x, canopyDistance.y + levelCanopyDistance.y);
                                        }
                                    }
                                    return canopyDistance;
                                })();
                            }
                            else if (((canopy != null && (canopy["__interfaces"] != null && canopy["__interfaces"].indexOf("com.livewings.spotassist.library.json.ICanopy") >= 0 || canopy.constructor != null && canopy.constructor["__interfaces"] != null && canopy.constructor["__interfaces"].indexOf("com.livewings.spotassist.library.json.ICanopy") >= 0)) || canopy === null) && ((typeof canopyFlyingDirection === 'number') || canopyFlyingDirection === null) && ((typeof fromAltitude === 'number') || fromAltitude === null) && ((typeof toAltitude === 'number') || toAltitude === null) && ((typeof flightUnits === 'number') || flightUnits === null) && drawingWarnings === undefined) {
                                return com.livewings.spotassist.library.math.SkydiveMath.calcCanopyDistance$com_livewings_spotassist_library_json_ICanopy$double$double$double$double(canopy, canopyFlyingDirection, fromAltitude, toAltitude, flightUnits);
                            }
                            else
                                throw new Error('invalid overload');
                        };
                        SkydiveMath.calcCanopyDistance$com_livewings_spotassist_library_json_ICanopy$double$double$double$double = function (canopy, canopyFlyingDirection, canopyFlightTimeSec, windSpeedMs, windAngleFrom) {
                            var canopySpeedX = canopy.getSpeedMs() * Math.sin(/* toRadians */ (function (x) { return x * Math.PI / 180; })(canopyFlyingDirection));
                            var canopySpeedY = canopy.getSpeedMs() * Math.cos(/* toRadians */ (function (x) { return x * Math.PI / 180; })(canopyFlyingDirection));
                            var windSpeedX = windSpeedMs * Math.sin(/* toRadians */ (function (x) { return x * Math.PI / 180; })(windAngleFrom));
                            var windSpeedY = windSpeedMs * Math.cos(/* toRadians */ (function (x) { return x * Math.PI / 180; })(windAngleFrom));
                            var groundSpeedX = canopySpeedX - windSpeedX;
                            var groundSpeedY = canopySpeedY + windSpeedY;
                            return new com.livewings.spotassist.library.crossdata.PointF((groundSpeedX * canopyFlightTimeSec), (groundSpeedY * canopyFlightTimeSec));
                        };
                        SkydiveMath.calcCrabAngle = function (canopyCourseAngle, windAngleFrom, canopySpeed, windSpeed) {
                            var angle = windAngleFrom - canopyCourseAngle;
                            var sinAwa = Math.sin(/* toRadians */ (function (x) { return x * Math.PI / 180; })(angle));
                            var mult = windSpeed * sinAwa / canopySpeed;
                            var crab = (function (x) { return x * 180 / Math.PI; })(Math.asin(mult));
                            return crab;
                        };
                        SkydiveMath.isCanopyBackwardFlying = function (canopy, flightUnits) {
                            var backward = false;
                            for (var index130 = flightUnits.iterator(); index130.hasNext();) {
                                var flightUnit = index130.next();
                                {
                                    if (flightUnit.getWindUnit().getWindSpeedMs() >= canopy.getSpeedMs()) {
                                        backward = true;
                                    }
                                }
                            }
                            return backward;
                        };
                        SkydiveMath.calcRangePolygonGeo = function (dropzone, canopy, flightUnits, fromAltitude, toAltitude, drawingWarnings) {
                            var from = dropzone.getTargetLatLng();
                            var polygon = (new java.util.ArrayList());
                            for (var canopyAngle = 0; canopyAngle <= 360; canopyAngle += 10) {
                                var canopyDistance = SkydiveMath.calcCanopyDistance(canopy, canopyAngle, fromAltitude, toAltitude, flightUnits, drawingWarnings);
                                var reachedPoint = com.livewings.spotassist.library.math.MapTools.translateCoordinates(-canopyDistance.x, canopyDistance.y, from);
                                polygon.add(reachedPoint);
                            }
                            return polygon;
                        };
                        SkydiveMath.calcWindsArrowsGeo = function (dropzone, canopy, flightUnits, fromAltitude, toAltitude, useCanopyParams) {
                            var windArrows = (new java.util.ArrayList());
                            if (dropzone == null) {
                                return windArrows;
                            }
                            var lastMarkerPosition = dropzone.getTargetLatLng();
                            var lastFlightUnit = null;
                            for (var index131 = flightUnits.iterator(); index131.hasNext();) {
                                var flightUnit = index131.next();
                                {
                                    lastFlightUnit = flightUnit;
                                    var flightLevelAltitude = flightUnit.getTopAltitudeFt();
                                    var windFrom = flightUnit.getWindUnit().getWindAngleFrom();
                                    var imageShiftXMeters = 0;
                                    var imageShiftYMeters = 0;
                                    lastMarkerPosition = com.livewings.spotassist.library.math.MapTools.translateCoordinates(imageShiftXMeters, imageShiftYMeters, lastMarkerPosition);
                                    var windTo = SkydiveMath.getDirectionReciprocal(windFrom);
                                    var levelAltitudeTopFt = flightLevelAltitude;
                                    var levelAltitudeBottomFt = flightUnit.getBottomAltitudeFt();
                                    var canopyFlightTimeSec = SkydiveMath.getCanopyFlightTimeSec(canopy, levelAltitudeTopFt, levelAltitudeBottomFt);
                                    var noWindCanopyRange = SkydiveMath.calcCanopyDistance(canopy, -windTo, canopyFlightTimeSec, 0, -windTo);
                                    var driftPoint = new com.livewings.spotassist.library.crossdata.PointF(0, 0);
                                    if (useCanopyParams) {
                                        driftPoint = SkydiveMath.calcCanopyWindDrift(canopy, -windTo, flightUnit.getWindUnit().getWindSpeedMs(), levelAltitudeTopFt, levelAltitudeBottomFt);
                                    }
                                    var windMarkerPosition = com.livewings.spotassist.library.math.MapTools.translateCoordinates(noWindCanopyRange.x + driftPoint.x, -(noWindCanopyRange.y + driftPoint.y), lastMarkerPosition);
                                    windArrows.add(new com.livewings.spotassist.library.WindArrowData(windMarkerPosition, flightUnit));
                                    lastMarkerPosition = com.livewings.spotassist.library.math.MapTools.translateCoordinates(-imageShiftXMeters, -imageShiftYMeters, windMarkerPosition);
                                }
                            }
                            return windArrows;
                        };
                        SkydiveMath.calcPatternGeo = function (dropzone, canopy, flightUnits, patternPoints, pattern, selectedLandingDirection, detailed) {
                            if (dropzone == null) {
                                return null;
                            }
                            var patternData = new com.livewings.spotassist.library.PatternData();
                            var flightUnit = flightUnits.get(0);
                            var windSpeedMs = flightUnit.getWindUnit().getWindSpeedMs();
                            var windAngleFrom = flightUnit.getWindUnit().getWindAngleFrom();
                            var canopySpeedMs = canopy.getSpeedMs();
                            var canopyBackwardFlying = SkydiveMath.isCanopyBackwardFlying(canopy, flightUnits);
                            patternData.setCanopyPatternBackwardFlying(canopyBackwardFlying);
                            var downwindCanopyCourse = SkydiveMath.getDirectionReciprocal(windAngleFrom);
                            if (selectedLandingDirection >= 0) {
                                downwindCanopyCourse = SkydiveMath.getDirectionReciprocal(selectedLandingDirection);
                            }
                            var patternPointsDataList = (new java.util.ArrayList());
                            var dropzonePatternPointData = new com.livewings.spotassist.library.PatternPointData(null, dropzone.getTargetLatLng());
                            patternPointsDataList.add(dropzonePatternPointData);
                            var patternFlyDataList = (new java.util.ArrayList());
                            var currentCanopyReciprocalCourse = downwindCanopyCourse;
                            var currentPoint = dropzone.getTargetLatLng();
                            var lastAltitude = 0;
                            for (var index132 = 0; index132 < patternPoints.length; index132++) {
                                var p = patternPoints[index132];
                                {
                                    switch ((pattern)) {
                                        case com.livewings.spotassist.library.json.Pattern.LEFT:
                                            currentCanopyReciprocalCourse = downwindCanopyCourse + p.getAngleFromFinal();
                                            break;
                                        case com.livewings.spotassist.library.json.Pattern.RIGHT:
                                            currentCanopyReciprocalCourse = downwindCanopyCourse - p.getAngleFromFinal();
                                            break;
                                    }
                                    var crabAngle = SkydiveMath.calcCrabAngle(currentCanopyReciprocalCourse, windAngleFrom, canopySpeedMs, windSpeedMs);
                                    var turnAltitude = p.getTurnAltitude(dropzone);
                                    var canopyFlightTimeSec = SkydiveMath.getCanopyFlightTimeSec(canopy, turnAltitude, lastAltitude);
                                    var nextCanopyDistances = SkydiveMath.calcCanopyDistance(canopy, -(currentCanopyReciprocalCourse - crabAngle), canopyFlightTimeSec, windSpeedMs, windAngleFrom);
                                    var nextPoint = com.livewings.spotassist.library.math.MapTools.translateCoordinates(-nextCanopyDistances.x, nextCanopyDistances.y, new com.livewings.spotassist.library.crossdata.MapPoint(currentPoint.latitude, currentPoint.longitude));
                                    var patternPointData = new com.livewings.spotassist.library.PatternPointData(p, nextPoint);
                                    patternPointsDataList.add(patternPointData);
                                    if (detailed) {
                                        var crabPoint = com.livewings.spotassist.library.math.MapTools.translateCoordinates(-nextCanopyDistances.x / 2, nextCanopyDistances.y / 2, currentPoint);
                                        var currentCanopyCourse = SkydiveMath.getDirectionReciprocal(currentCanopyReciprocalCourse);
                                        var rotation = currentCanopyCourse - (crabAngle | 0);
                                        var patternFlyData = new com.livewings.spotassist.library.PatternFlyData(p, crabPoint, (crabAngle | 0), rotation);
                                        patternFlyDataList.add(patternFlyData);
                                    }
                                    lastAltitude = turnAltitude;
                                    currentPoint = nextPoint;
                                }
                            }
                            patternData.setPatternPointData(patternPointsDataList);
                            patternData.setPatternFlyData(patternFlyDataList);
                            return patternData;
                        };
                        SkydiveMath.translateWithWind = function (point, bitmapWidthMeters, bitmapHeightMeters, windFlightUnit) {
                            var shiftXMeters = bitmapWidthMeters * 1.5 * Math.sin(/* toRadians */ (function (x) { return x * Math.PI / 180; })(windFlightUnit.getWindUnit().getWindAngleFrom()));
                            var shiftYMeters = bitmapHeightMeters * 1.5 * Math.cos(/* toRadians */ (function (x) { return x * Math.PI / 180; })(windFlightUnit.getWindUnit().getWindAngleFrom()));
                            point = com.livewings.spotassist.library.math.MapTools.translateCoordinates(shiftXMeters, shiftYMeters, new com.livewings.spotassist.library.crossdata.MapPoint(point.latitude, point.longitude));
                            return point;
                        };
                        SkydiveMath.getCanopyFlightTimeSec = function (canopy, altitudeTopFt, altitudeBottomFt) {
                            var canopyGlideRatio = canopy.getGlideRatio();
                            var canopySpeedMs = canopy.getSpeedMs();
                            var canopyFlightTime = (Math.abs(altitudeTopFt - altitudeBottomFt) * com.livewings.spotassist.library.math.Conversion.FEET_TO_METERS * canopyGlideRatio) / canopySpeedMs;
                            return canopyFlightTime;
                        };
                        SkydiveMath.getUpCorridorLandingDirection = function (windDirection, corridorLandingDirection) {
                            var landingUpwindDirection = windDirection;
                            if (corridorLandingDirection < 0) {
                                return landingUpwindDirection;
                            }
                            var landingDirection = corridorLandingDirection;
                            var angleDiff = (landingUpwindDirection - corridorLandingDirection + 180) % 360 - 180;
                            if (angleDiff > 90 || angleDiff < -90) {
                                var corridorLandingDirectionReciprocal = SkydiveMath.getDirectionReciprocal(corridorLandingDirection);
                                landingDirection = corridorLandingDirectionReciprocal;
                            }
                            return landingDirection;
                        };
                        SkydiveMath.calcLandingDirectionStrategies = function (corridorLandingDirection) {
                            if (corridorLandingDirection < 0) {
                                return java.util.Arrays.asList(com.livewings.spotassist.library.math.PatternStrategy.values());
                            }
                            else if ((corridorLandingDirection > (360 - 45) || corridorLandingDirection < 45) || (corridorLandingDirection > (90 + 45) && corridorLandingDirection < 180 + 45)) {
                                return java.util.Arrays.asList([com.livewings.spotassist.library.math.PatternStrategy.PATTERN_NO_STRATEGY_$LI$(), com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_EAST_$LI$(), com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_WEST_$LI$(), com.livewings.spotassist.library.math.PatternStrategy.PATTERN_ALWAYS_LEFT_$LI$(), com.livewings.spotassist.library.math.PatternStrategy.PATTERN_ALWAYS_RIGHT_$LI$()]);
                            }
                            else {
                                return java.util.Arrays.asList([com.livewings.spotassist.library.math.PatternStrategy.PATTERN_NO_STRATEGY_$LI$(), com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_NORTH_$LI$(), com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_SOUTH_$LI$(), com.livewings.spotassist.library.math.PatternStrategy.PATTERN_ALWAYS_LEFT_$LI$(), com.livewings.spotassist.library.math.PatternStrategy.PATTERN_ALWAYS_RIGHT_$LI$()]);
                            }
                        };
                        SkydiveMath.calcPatternForLandingDirectionAndStrategyString = function (landingDirection, patternStrategyString) {
                            var patternStrategy = com.livewings.spotassist.library.math.PatternStrategy.valueOf(patternStrategyString);
                            return SkydiveMath.calcPatternForLandingDirectionAndStrategy(landingDirection, patternStrategy);
                        };
                        SkydiveMath.calcPatternForLandingDirectionAndStrategy = function (landingDirection, patternStrategy) {
                            if (patternStrategy === com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_NORTH_$LI$()) {
                                if (landingDirection >= 0 && landingDirection <= 180) {
                                    return com.livewings.spotassist.library.json.Pattern.LEFT;
                                }
                                else {
                                    return com.livewings.spotassist.library.json.Pattern.RIGHT;
                                }
                            }
                            else if (patternStrategy === com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_SOUTH_$LI$()) {
                                if (landingDirection >= 180 && landingDirection <= 360) {
                                    return com.livewings.spotassist.library.json.Pattern.LEFT;
                                }
                                else {
                                    return com.livewings.spotassist.library.json.Pattern.RIGHT;
                                }
                            }
                            else if (patternStrategy === com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_EAST_$LI$()) {
                                if (landingDirection >= 90 && landingDirection < 270) {
                                    return com.livewings.spotassist.library.json.Pattern.LEFT;
                                }
                                else {
                                    return com.livewings.spotassist.library.json.Pattern.RIGHT;
                                }
                            }
                            else if (patternStrategy === com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_WEST_$LI$()) {
                                if (landingDirection >= 270 || landingDirection < 90) {
                                    return com.livewings.spotassist.library.json.Pattern.LEFT;
                                }
                                else {
                                    return com.livewings.spotassist.library.json.Pattern.RIGHT;
                                }
                            }
                            else if (patternStrategy === com.livewings.spotassist.library.math.PatternStrategy.PATTERN_ALWAYS_RIGHT_$LI$()) {
                                return com.livewings.spotassist.library.json.Pattern.RIGHT;
                            }
                            else if (patternStrategy === com.livewings.spotassist.library.math.PatternStrategy.PATTERN_ALWAYS_LEFT_$LI$()) {
                                return com.livewings.spotassist.library.json.Pattern.LEFT;
                            }
                            else {
                                return com.livewings.spotassist.library.json.Pattern.LEFT;
                            }
                        };
                        SkydiveMath.g = 9.8;
                        SkydiveMath.BELLY_B = 0.0025;
                        SkydiveMath.FREEFLY_B = 0.0015;
                        return SkydiveMath;
                    }());
                    math.SkydiveMath = SkydiveMath;
                    SkydiveMath["__class"] = "com.livewings.spotassist.library.math.SkydiveMath";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var MapTools = (function () {
                        function MapTools() {
                        }
                        MapTools.translateCoordinates = function (distance, origpoint, angle) {
                            if (((typeof distance === 'number') || distance === null) && ((origpoint != null && origpoint instanceof com.livewings.spotassist.library.crossdata.MapPoint) || origpoint === null) && ((typeof angle === 'number') || angle === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                return (function () {
                                    var distanceNorth = Math.cos(/* toRadians */ (function (x) { return x * Math.PI / 180; })(angle)) * distance;
                                    var distanceEast = Math.sin(/* toRadians */ (function (x) { return x * Math.PI / 180; })(angle)) * distance;
                                    return MapTools.translateCoordinates(distanceEast, distanceNorth, origpoint);
                                })();
                            }
                            else if (((typeof distance === 'number') || distance === null) && ((typeof origpoint === 'number') || origpoint === null) && ((angle != null && angle instanceof com.livewings.spotassist.library.crossdata.MapPoint) || angle === null)) {
                                return com.livewings.spotassist.library.math.MapTools.translateCoordinates$double$double$com_livewings_spotassist_library_crossdata_MapPoint(distance, origpoint, angle);
                            }
                            else
                                throw new Error('invalid overload');
                        };
                        MapTools.translateCoordinates$double$double$com_livewings_spotassist_library_crossdata_MapPoint = function (distanceEast, distanceNorth, origpoint) {
                            var earthRadius = 6378137;
                            var newLat = origpoint.latitude + (180 / Math.PI) * (distanceNorth / earthRadius);
                            var newLon = origpoint.longitude + (180 / Math.PI) * (distanceEast / earthRadius) / Math.cos(Math.PI / 180.0 * origpoint.latitude);
                            return new com.livewings.spotassist.library.crossdata.MapPoint(newLat, newLon);
                        };
                        MapTools.containsInPolygon = function (point, polygon) {
                            var oddTransitions = false;
                            var points = polygon.getPolygonPoints();
                            var polyY;
                            var polyX;
                            var x = (point.latitude);
                            var y = (point.longitude);
                            polyY = new Array(points.size());
                            polyX = new Array(points.size());
                            for (var i = 0; i < points.size(); i++) {
                                var p = points.get(i);
                                polyY[i] = (p.longitude);
                                polyX[i] = (p.latitude);
                            }
                            for (var i = 0, j = points.size() - 1; i < points.size(); j = i++) {
                                if ((polyY[i] < y && polyY[j] >= y) || (polyY[j] < y && polyY[i] >= y) && (polyX[i] <= x || polyX[j] <= x)) {
                                    if (polyX[i] + (y - polyY[i]) / (polyY[j] - polyY[i]) * (polyX[j] - polyX[i]) < x) {
                                        oddTransitions = !oddTransitions;
                                    }
                                }
                            }
                            return oddTransitions;
                        };
                        MapTools.distanceBetweenPointsM = function (p1, p2) {
                            var pk = (180 / 3.14169);
                            var a1 = p1.latitude / pk;
                            var a2 = p1.longitude / pk;
                            var b1 = p2.latitude / pk;
                            var b2 = p2.longitude / pk;
                            var t1 = Math.cos(a1) * Math.cos(a2) * Math.cos(b1) * Math.cos(b2);
                            var t2 = Math.cos(a1) * Math.sin(a2) * Math.cos(b1) * Math.sin(b2);
                            var t3 = Math.sin(a1) * Math.sin(b1);
                            var tt = Math.acos(t1 + t2 + t3);
                            return 6366000 * tt;
                        };
                        MapTools.getAltitudeMeters = function (mapZoom) {
                            var altitude;
                            var firstPartOfEq = (0.05 * ((5.916575505E8 / (Math.pow(2, (mapZoom - 1)))) / 2));
                            altitude = (firstPartOfEq) * ((Math.cos(/* toRadians */ (function (x) { return x * Math.PI / 180; })(85.362 / 2))) / (Math.sin(/* toRadians */ (function (x) { return x * Math.PI / 180; })(85.362 / 2))));
                            return altitude;
                        };
                        return MapTools;
                    }());
                    math.MapTools = MapTools;
                    MapTools["__class"] = "com.livewings.spotassist.library.math.MapTools";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    (function (StationType) {
                        StationType[StationType["METAR"] = 0] = "METAR";
                        StationType[StationType["rawinsonde"] = 1] = "rawinsonde";
                        StationType[StationType["TAF"] = 2] = "TAF";
                        StationType[StationType["NEXRAD"] = 3] = "NEXRAD";
                        StationType[StationType["wind_profiler"] = 4] = "wind_profiler";
                        StationType[StationType["WFO_office"] = 5] = "WFO_office";
                        StationType[StationType["SYNOPS"] = 6] = "SYNOPS";
                        StationType[StationType["windsaloft"] = 7] = "windsaloft";
                        StationType[StationType["openweather"] = 8] = "openweather";
                    })(json.StationType || (json.StationType = {}));
                    var StationType = json.StationType;
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var Forecast = (function () {
                        function Forecast(fcst_time_from, fcst_time_to, time_becoming, change_indicator, wind_dir_degrees, wind_speed_kt, visibility_statute_mi, wind_gust_kt, wx_string, probability, temp_c, sky_conditions) {
                            var _this = this;
                            this.wind_dir_degrees = javaemul.internal.IntegerHelper.MIN_VALUE;
                            this.wind_speed_kt = javaemul.internal.DoubleHelper.MIN_VALUE;
                            this.visibility_statute_mi = javaemul.internal.DoubleHelper.MIN_VALUE;
                            this.wind_gust_kt = javaemul.internal.DoubleHelper.MIN_VALUE;
                            this.probability = javaemul.internal.IntegerHelper.MIN_VALUE;
                            this.temp_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                            if (((typeof fcst_time_from === 'string') || fcst_time_from === null) && ((typeof fcst_time_to === 'string') || fcst_time_to === null) && ((typeof time_becoming === 'string') || time_becoming === null) && ((typeof change_indicator === 'string') || change_indicator === null) && ((typeof wind_dir_degrees === 'number') || wind_dir_degrees === null) && ((typeof wind_speed_kt === 'number') || wind_speed_kt === null) && ((typeof visibility_statute_mi === 'number') || visibility_statute_mi === null) && ((typeof wind_gust_kt === 'number') || wind_gust_kt === null) && ((typeof wx_string === 'string') || wx_string === null) && ((typeof probability === 'number') || probability === null) && ((typeof temp_c === 'number') || temp_c === null) && ((sky_conditions != null && (sky_conditions["__interfaces"] != null && sky_conditions["__interfaces"].indexOf("java.util.List") >= 0 || sky_conditions.constructor != null && sky_conditions.constructor["__interfaces"] != null && sky_conditions.constructor["__interfaces"].indexOf("java.util.List") >= 0)) || sky_conditions === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                {
                                    var __args_1 = Array.prototype.slice.call(arguments);
                                    this.wind_dir_degrees = javaemul.internal.IntegerHelper.MIN_VALUE;
                                    this.wind_speed_kt = javaemul.internal.DoubleHelper.MIN_VALUE;
                                    this.visibility_statute_mi = javaemul.internal.DoubleHelper.MIN_VALUE;
                                    this.wind_gust_kt = javaemul.internal.DoubleHelper.MIN_VALUE;
                                    this.probability = javaemul.internal.IntegerHelper.MIN_VALUE;
                                    this.temp_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                                    (function () {
                                        _this.fcst_time_from = fcst_time_from;
                                        _this.fcst_time_to = fcst_time_to;
                                        _this.time_becoming = time_becoming;
                                        _this.change_indicator = change_indicator;
                                        _this.wind_dir_degrees = wind_dir_degrees;
                                        _this.wind_speed_kt = wind_speed_kt;
                                        _this.visibility_statute_mi = visibility_statute_mi;
                                        _this.wind_gust_kt = wind_gust_kt;
                                        _this.wx_string = wx_string;
                                        _this.probability = probability;
                                        _this.sky_conditions = sky_conditions;
                                    })();
                                }
                                (function () {
                                    _this.temp_c = temp_c;
                                })();
                            }
                            else if (((typeof fcst_time_from === 'string') || fcst_time_from === null) && ((typeof fcst_time_to === 'string') || fcst_time_to === null) && ((typeof time_becoming === 'string') || time_becoming === null) && ((typeof change_indicator === 'string') || change_indicator === null) && ((typeof wind_dir_degrees === 'number') || wind_dir_degrees === null) && ((typeof wind_speed_kt === 'number') || wind_speed_kt === null) && ((typeof visibility_statute_mi === 'number') || visibility_statute_mi === null) && ((typeof wind_gust_kt === 'number') || wind_gust_kt === null) && ((typeof wx_string === 'string') || wx_string === null) && ((typeof probability === 'number') || probability === null) && ((temp_c != null && (temp_c["__interfaces"] != null && temp_c["__interfaces"].indexOf("java.util.List") >= 0 || temp_c.constructor != null && temp_c.constructor["__interfaces"] != null && temp_c.constructor["__interfaces"].indexOf("java.util.List") >= 0)) || temp_c === null) && sky_conditions === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                                var sky_conditions_1 = __args[10];
                                this.wind_dir_degrees = javaemul.internal.IntegerHelper.MIN_VALUE;
                                this.wind_speed_kt = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.visibility_statute_mi = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.wind_gust_kt = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.probability = javaemul.internal.IntegerHelper.MIN_VALUE;
                                this.temp_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                                (function () {
                                    _this.fcst_time_from = fcst_time_from;
                                    _this.fcst_time_to = fcst_time_to;
                                    _this.time_becoming = time_becoming;
                                    _this.change_indicator = change_indicator;
                                    _this.wind_dir_degrees = wind_dir_degrees;
                                    _this.wind_speed_kt = wind_speed_kt;
                                    _this.visibility_statute_mi = visibility_statute_mi;
                                    _this.wind_gust_kt = wind_gust_kt;
                                    _this.wx_string = wx_string;
                                    _this.probability = probability;
                                    _this.sky_conditions = sky_conditions_1;
                                })();
                            }
                            else if (fcst_time_from === undefined && fcst_time_to === undefined && time_becoming === undefined && change_indicator === undefined && wind_dir_degrees === undefined && wind_speed_kt === undefined && visibility_statute_mi === undefined && wind_gust_kt === undefined && wx_string === undefined && probability === undefined && temp_c === undefined && sky_conditions === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.wind_dir_degrees = javaemul.internal.IntegerHelper.MIN_VALUE;
                                this.wind_speed_kt = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.visibility_statute_mi = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.wind_gust_kt = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.probability = javaemul.internal.IntegerHelper.MIN_VALUE;
                                this.temp_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        Forecast.dummyPhenomenas_$LI$ = function () { if (Forecast.dummyPhenomenas == null)
                            Forecast.dummyPhenomenas = new java.util.ArrayList(); return Forecast.dummyPhenomenas; };
                        ;
                        Forecast.prototype.getPhenomenas = function () {
                            return Forecast.dummyPhenomenas_$LI$();
                        };
                        Forecast.prototype.getFcst_time_from = function () {
                            return this.fcst_time_from;
                        };
                        Forecast.prototype.getFcst_time_to = function () {
                            return this.fcst_time_to;
                        };
                        Forecast.prototype.getTime_becoming = function () {
                            return this.time_becoming;
                        };
                        Forecast.prototype.getChange_indicator = function () {
                            return this.change_indicator;
                        };
                        Forecast.prototype.getWind_dir_degrees = function () {
                            return this.wind_dir_degrees;
                        };
                        Forecast.prototype.getWind_speed_kt = function () {
                            return this.wind_speed_kt;
                        };
                        Forecast.prototype.getVisibility_statute_mi = function () {
                            return this.visibility_statute_mi;
                        };
                        Forecast.prototype.getWind_gust_kt = function () {
                            return this.wind_gust_kt;
                        };
                        Forecast.prototype.getWx_string = function () {
                            return this.wx_string;
                        };
                        Forecast.prototype.getProbability = function () {
                            return this.probability;
                        };
                        Forecast.prototype.getTemp_c = function () {
                            return this.temp_c;
                        };
                        Forecast.prototype.getSky_conditions = function () {
                            return this.sky_conditions;
                        };
                        return Forecast;
                    }());
                    json.Forecast = Forecast;
                    Forecast["__class"] = "com.livewings.spotassist.library.json.Forecast";
                    Forecast["__interfaces"] = ["com.livewings.spotassist.library.math.DisplayableWeather"];
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var Rawinsonde = (function () {
                        function Rawinsonde(valid, sondeLevels) {
                            var _this = this;
                            if (((typeof valid === 'string') || valid === null) && ((sondeLevels != null && (sondeLevels["__interfaces"] != null && sondeLevels["__interfaces"].indexOf("java.util.List") >= 0 || sondeLevels.constructor != null && sondeLevels.constructor["__interfaces"] != null && sondeLevels.constructor["__interfaces"].indexOf("java.util.List") >= 0)) || sondeLevels === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                (function () {
                                    _this.valid = valid;
                                    _this.sondeLevels = sondeLevels;
                                })();
                            }
                            else if (valid === undefined && sondeLevels === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        Rawinsonde.prototype.getValid = function () {
                            return this.valid;
                        };
                        Rawinsonde.prototype.getSondeLevels = function () {
                            return this.sondeLevels;
                        };
                        Rawinsonde.prototype.equals = function (obj) {
                            if (obj == null || !(obj != null && obj instanceof com.livewings.spotassist.library.json.Rawinsonde)) {
                                return false;
                            }
                            var another = obj;
                            var newValid = another == null ? null : another.getValid();
                            if (this.getValid() != null && !(this.getValid() === newValid)) {
                                return false;
                            }
                            return true;
                        };
                        Rawinsonde.prototype.toString = function () {
                            return "valid_from=" + this.getValid() + " sondelevels number=" + this.getSondeLevels().size();
                        };
                        return Rawinsonde;
                    }());
                    json.Rawinsonde = Rawinsonde;
                    Rawinsonde["__class"] = "com.livewings.spotassist.library.json.Rawinsonde";
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var ForecastPhenomena = (function () {
                        function ForecastPhenomena(code, description) {
                            var _this = this;
                            if (((typeof code === 'number') || code === null) && ((typeof description === 'string') || description === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.code = 0;
                                (function () {
                                    _this.code = code;
                                    _this.description = description;
                                })();
                            }
                            else if (code === undefined && description === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.code = 0;
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        ForecastPhenomena.prototype.getCode = function () {
                            return this.code;
                        };
                        ForecastPhenomena.prototype.getDescription = function () {
                            return this.description;
                        };
                        return ForecastPhenomena;
                    }());
                    json.ForecastPhenomena = ForecastPhenomena;
                    ForecastPhenomena["__class"] = "com.livewings.spotassist.library.json.ForecastPhenomena";
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var Taf = (function () {
                        function Taf(issue_time, forecasts) {
                            var _this = this;
                            if (((typeof issue_time === 'string') || issue_time === null) && ((forecasts != null && (forecasts["__interfaces"] != null && forecasts["__interfaces"].indexOf("java.util.List") >= 0 || forecasts.constructor != null && forecasts.constructor["__interfaces"] != null && forecasts.constructor["__interfaces"].indexOf("java.util.List") >= 0)) || forecasts === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                (function () {
                                    _this.issue_time = issue_time;
                                    _this.forecasts = forecasts;
                                })();
                            }
                            else if (issue_time === undefined && forecasts === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        Taf.prototype.getIssue_time = function () {
                            return this.issue_time;
                        };
                        Taf.prototype.getForecasts = function () {
                            return this.forecasts;
                        };
                        Taf.prototype.equals = function (obj) {
                            if (obj == null || !(obj != null && obj instanceof com.livewings.spotassist.library.json.Taf)) {
                                return false;
                            }
                            var another = obj;
                            var newIssueTime = another == null ? null : another.getIssue_time();
                            if (this.getIssue_time() != null && !(this.getIssue_time() === newIssueTime)) {
                                return false;
                            }
                            return true;
                        };
                        Taf.prototype.toString = function () {
                            return "issue_time=" + this.getIssue_time() + " forecasts number=" + this.getForecasts().size();
                        };
                        return Taf;
                    }());
                    json.Taf = Taf;
                    Taf["__class"] = "com.livewings.spotassist.library.json.Taf";
                    Taf["__interfaces"] = ["com.livewings.spotassist.library.json.ITaf"];
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var Metar = (function () {
                        function Metar(temp_c, dewpoint_c, altim_in_hg, wind_dir_degrees, wind_speed_kt, wind_gust_kt, ws_string, visibility_statute_mi, observation_time, sky_conditions) {
                            var _this = this;
                            this.temp_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                            this.dewpoint_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                            this.altim_in_hg = javaemul.internal.DoubleHelper.MIN_VALUE;
                            this.wind_dir_degrees = -1;
                            this.wind_speed_kt = -1;
                            this.wind_gust_kt = -1;
                            this.visibility_statute_mi = -1;
                            if (((typeof temp_c === 'number') || temp_c === null) && ((typeof dewpoint_c === 'number') || dewpoint_c === null) && ((typeof altim_in_hg === 'number') || altim_in_hg === null) && ((typeof wind_dir_degrees === 'number') || wind_dir_degrees === null) && ((typeof wind_speed_kt === 'number') || wind_speed_kt === null) && ((typeof wind_gust_kt === 'number') || wind_gust_kt === null) && ((typeof ws_string === 'string') || ws_string === null) && ((typeof visibility_statute_mi === 'number') || visibility_statute_mi === null) && ((typeof observation_time === 'string') || observation_time === null) && ((sky_conditions != null && (sky_conditions["__interfaces"] != null && sky_conditions["__interfaces"].indexOf("java.util.List") >= 0 || sky_conditions.constructor != null && sky_conditions.constructor["__interfaces"] != null && sky_conditions.constructor["__interfaces"].indexOf("java.util.List") >= 0)) || sky_conditions === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.temp_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.dewpoint_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.altim_in_hg = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.wind_dir_degrees = -1;
                                this.wind_speed_kt = -1;
                                this.wind_gust_kt = -1;
                                this.visibility_statute_mi = -1;
                                this.historical = false;
                                (function () {
                                    _this.temp_c = temp_c;
                                    _this.dewpoint_c = dewpoint_c;
                                    _this.altim_in_hg = altim_in_hg;
                                    _this.wind_dir_degrees = wind_dir_degrees;
                                    _this.wind_speed_kt = wind_speed_kt;
                                    _this.wind_gust_kt = wind_gust_kt;
                                    _this.visibility_statute_mi = visibility_statute_mi;
                                    _this.observation_time = observation_time;
                                    _this.sky_conditions = sky_conditions;
                                })();
                            }
                            else if (temp_c === undefined && dewpoint_c === undefined && altim_in_hg === undefined && wind_dir_degrees === undefined && wind_speed_kt === undefined && wind_gust_kt === undefined && ws_string === undefined && visibility_statute_mi === undefined && observation_time === undefined && sky_conditions === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.temp_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.dewpoint_c = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.altim_in_hg = javaemul.internal.DoubleHelper.MIN_VALUE;
                                this.wind_dir_degrees = -1;
                                this.wind_speed_kt = -1;
                                this.wind_gust_kt = -1;
                                this.visibility_statute_mi = -1;
                                this.historical = false;
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        Metar.dummyPhenomenas_$LI$ = function () { if (Metar.dummyPhenomenas == null)
                            Metar.dummyPhenomenas = new java.util.ArrayList(); return Metar.dummyPhenomenas; };
                        ;
                        Metar.prototype.getTemp_c = function () {
                            return this.temp_c;
                        };
                        Metar.prototype.getDewpoint_c = function () {
                            return this.dewpoint_c;
                        };
                        Metar.prototype.getAltim_in_hg = function () {
                            return this.altim_in_hg;
                        };
                        Metar.prototype.getWind_dir_degrees = function () {
                            return this.wind_dir_degrees;
                        };
                        Metar.prototype.getWind_speed_kt = function () {
                            return this.wind_speed_kt;
                        };
                        Metar.prototype.getWind_gust_kt = function () {
                            return this.wind_gust_kt;
                        };
                        Metar.prototype.getWx_string = function () {
                            return this.wx_string;
                        };
                        Metar.prototype.getVisibility_statute_mi = function () {
                            return this.visibility_statute_mi;
                        };
                        Metar.prototype.getObservation_time = function () {
                            return this.observation_time;
                        };
                        Metar.prototype.getRaw_text = function () {
                            return null;
                        };
                        Metar.prototype.getFlight_category = function () {
                            return this.flight_category;
                        };
                        Metar.prototype.getSky_conditions = function () {
                            return this.sky_conditions;
                        };
                        Metar.prototype.isHistorical = function () {
                            return this.historical;
                        };
                        Metar.prototype.setHistorical = function (historical) {
                            this.historical = historical;
                        };
                        Metar.prototype.getPhenomenas = function () {
                            return Metar.dummyPhenomenas_$LI$();
                        };
                        Metar.prototype.getWindSpeedMs = function () {
                            return this.getWind_speed_kt() * com.livewings.spotassist.library.math.Conversion.KNOTS_TO_METERSPERSEC;
                        };
                        Metar.prototype.equals = function (obj) {
                            if (obj == null || !(obj != null && obj instanceof com.livewings.spotassist.library.json.Metar)) {
                                return false;
                            }
                            var another = obj;
                            var newObservationTime = another == null ? null : another.getObservation_time();
                            if (this.getObservation_time() != null && !(this.getObservation_time() === newObservationTime)) {
                                return false;
                            }
                            return true;
                        };
                        Metar.prototype.toString = function () {
                            return " observation_time=" + this.getObservation_time() + " wind_dir_degrees=" + this.getWind_dir_degrees() + " wind_speed_kt=" + this.getWind_speed_kt();
                        };
                        return Metar;
                    }());
                    json.Metar = Metar;
                    Metar["__class"] = "com.livewings.spotassist.library.json.Metar";
                    Metar["__interfaces"] = ["com.livewings.spotassist.library.json.IMetar", "com.livewings.spotassist.library.math.DisplayableWeather"];
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var SkyCondition = (function () {
                        function SkyCondition(sky_cover, sky_cover_human, cloud_base_ft_agl) {
                            var _this = this;
                            this.cloud_base_ft_agl = -1;
                            if (((typeof sky_cover === 'string') || sky_cover === null) && ((typeof sky_cover_human === 'string') || sky_cover_human === null) && ((typeof cloud_base_ft_agl === 'number') || cloud_base_ft_agl === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.cloud_base_ft_agl = -1;
                                (function () {
                                    _this.sky_cover = sky_cover;
                                    _this.sky_cover_human = sky_cover_human;
                                    _this.cloud_base_ft_agl = cloud_base_ft_agl;
                                })();
                            }
                            else if (sky_cover === undefined && sky_cover_human === undefined && cloud_base_ft_agl === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.cloud_base_ft_agl = -1;
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        SkyCondition.prototype.getSky_cover = function () {
                            return this.sky_cover;
                        };
                        SkyCondition.prototype.getSky_cover_human = function () {
                            return this.sky_cover_human;
                        };
                        SkyCondition.prototype.getCloud_base_ft_agl = function () {
                            return this.cloud_base_ft_agl;
                        };
                        return SkyCondition;
                    }());
                    json.SkyCondition = SkyCondition;
                    SkyCondition["__class"] = "com.livewings.spotassist.library.json.SkyCondition";
                    SkyCondition["__interfaces"] = ["com.livewings.spotassist.library.json.ISkyCondition"];
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    (function (ForecastPhenomenaCodes) {
                        ForecastPhenomenaCodes[ForecastPhenomenaCodes["AAA"] = 0] = "AAA";
                    })(json.ForecastPhenomenaCodes || (json.ForecastPhenomenaCodes = {}));
                    var ForecastPhenomenaCodes = json.ForecastPhenomenaCodes;
                    var ForecastPhenomenaCodes_$WRAPPER = (function () {
                        function ForecastPhenomenaCodes_$WRAPPER(_$ordinal, _$name, code, description) {
                            this._$ordinal = _$ordinal;
                            this._$name = _$name;
                            this.code = 0;
                            this.code = code;
                            this.description = description;
                        }
                        ForecastPhenomenaCodes_$WRAPPER.prototype.name = function () { return this._$name; };
                        ForecastPhenomenaCodes_$WRAPPER.prototype.ordinal = function () { return this._$ordinal; };
                        return ForecastPhenomenaCodes_$WRAPPER;
                    }());
                    json.ForecastPhenomenaCodes_$WRAPPER = ForecastPhenomenaCodes_$WRAPPER;
                    ForecastPhenomenaCodes["__class"] = "com.livewings.spotassist.library.json.ForecastPhenomenaCodes";
                    ForecastPhenomenaCodes["__interfaces"] = ["java.lang.Comparable", "java.io.Serializable"];
                    ForecastPhenomenaCodes["_$wrappers"] = [new ForecastPhenomenaCodes_$WRAPPER(0, "AAA", 200, "")];
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var FlightLevel = (function () {
                        function FlightLevel(flightlevel, direction, speed, temperature) {
                            var _this = this;
                            this.flightlevel = -1;
                            this.direction = -1;
                            this.speed = -1;
                            this.temperature = javaemul.internal.DoubleHelper.MIN_VALUE;
                            if (((typeof flightlevel === 'number') || flightlevel === null) && ((typeof direction === 'number') || direction === null) && ((typeof speed === 'number') || speed === null) && ((typeof temperature === 'number') || temperature === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.flightlevel = -1;
                                this.direction = -1;
                                this.speed = -1;
                                this.temperature = javaemul.internal.DoubleHelper.MIN_VALUE;
                                (function () {
                                    _this.flightlevel = flightlevel;
                                    _this.direction = direction;
                                    _this.speed = speed;
                                    _this.temperature = temperature;
                                })();
                            }
                            else if (flightlevel === undefined && direction === undefined && speed === undefined && temperature === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.flightlevel = -1;
                                this.direction = -1;
                                this.speed = -1;
                                this.temperature = javaemul.internal.DoubleHelper.MIN_VALUE;
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        FlightLevel.prototype.getFlightlevel = function () {
                            return this.flightlevel;
                        };
                        FlightLevel.prototype.getDirection = function () {
                            return this.direction;
                        };
                        FlightLevel.prototype.getSpeed = function () {
                            return this.speed;
                        };
                        FlightLevel.prototype.getSpeedMs = function () {
                            return this.speed * com.livewings.spotassist.library.math.Conversion.KNOTS_TO_METERSPERSEC;
                        };
                        FlightLevel.prototype.getTemperature = function () {
                            return this.temperature;
                        };
                        return FlightLevel;
                    }());
                    json.FlightLevel = FlightLevel;
                    FlightLevel["__class"] = "com.livewings.spotassist.library.json.FlightLevel";
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var Windaloft = (function () {
                        function Windaloft(valid_from, valid_to, flightLevels) {
                            var _this = this;
                            if (((typeof valid_from === 'string') || valid_from === null) && ((typeof valid_to === 'string') || valid_to === null) && ((flightLevels != null && (flightLevels["__interfaces"] != null && flightLevels["__interfaces"].indexOf("java.util.List") >= 0 || flightLevels.constructor != null && flightLevels.constructor["__interfaces"] != null && flightLevels.constructor["__interfaces"].indexOf("java.util.List") >= 0)) || flightLevels === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                (function () {
                                    _this.valid_from = valid_from;
                                    _this.valid_to = valid_to;
                                    _this.flightlevels = flightLevels;
                                })();
                            }
                            else if (valid_from === undefined && valid_to === undefined && flightLevels === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        Windaloft.prototype.getValid_from = function () {
                            return this.valid_from;
                        };
                        Windaloft.prototype.getValid_to = function () {
                            return this.valid_to;
                        };
                        Windaloft.prototype.getFlightLevels = function () {
                            return this.flightlevels;
                        };
                        Windaloft.prototype.getFlightLevel = function (flightLevelInt) {
                            for (var index133 = this.getFlightLevels().iterator(); index133.hasNext();) {
                                var flightLevel = index133.next();
                                {
                                    if (flightLevel.getFlightlevel() === flightLevelInt) {
                                        return flightLevel;
                                    }
                                }
                            }
                            return null;
                        };
                        Windaloft.prototype.equals = function (obj) {
                            if (obj == null || !(obj != null && obj instanceof com.livewings.spotassist.library.json.Windaloft)) {
                                return false;
                            }
                            var another = obj;
                            var newValidTo = another == null ? null : another.getValid_to();
                            if (this.getValid_to() != null && !(this.getValid_to() === newValidTo)) {
                                return false;
                            }
                            return true;
                        };
                        Windaloft.prototype.toString = function () {
                            return " valid_from=" + this.getValid_from() + " valid_to=" + this.getValid_to() + " flightlevels number=" + this.getFlightLevels().size();
                        };
                        return Windaloft;
                    }());
                    json.Windaloft = Windaloft;
                    Windaloft["__class"] = "com.livewings.spotassist.library.json.Windaloft";
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    var SondeLevel = (function () {
                        function SondeLevel(heightM, directionFrom, speedMs, tempC) {
                            var _this = this;
                            this.heightM = -1;
                            this.directionFrom = -1;
                            this.speedMs = -1;
                            this.tempC = javaemul.internal.DoubleHelper.MIN_VALUE;
                            if (((typeof heightM === 'number') || heightM === null) && ((typeof directionFrom === 'number') || directionFrom === null) && ((typeof speedMs === 'number') || speedMs === null) && ((typeof tempC === 'number') || tempC === null)) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.heightM = -1;
                                this.directionFrom = -1;
                                this.speedMs = -1;
                                this.tempC = javaemul.internal.DoubleHelper.MIN_VALUE;
                                (function () {
                                    _this.heightM = heightM;
                                    _this.directionFrom = directionFrom;
                                    _this.speedMs = speedMs;
                                    _this.tempC = tempC;
                                })();
                            }
                            else if (heightM === undefined && directionFrom === undefined && speedMs === undefined && tempC === undefined) {
                                var __args = Array.prototype.slice.call(arguments);
                                this.heightM = -1;
                                this.directionFrom = -1;
                                this.speedMs = -1;
                                this.tempC = javaemul.internal.DoubleHelper.MIN_VALUE;
                            }
                            else
                                throw new Error('invalid overload');
                        }
                        SondeLevel.prototype.getHeightM = function () {
                            return this.heightM;
                        };
                        SondeLevel.prototype.getDirectionFrom = function () {
                            return this.directionFrom;
                        };
                        SondeLevel.prototype.getSpeedMs = function () {
                            return this.speedMs;
                        };
                        SondeLevel.prototype.getTempC = function () {
                            return this.tempC;
                        };
                        return SondeLevel;
                    }());
                    json.SondeLevel = SondeLevel;
                    SondeLevel["__class"] = "com.livewings.spotassist.library.json.SondeLevel";
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                /**
                 * Created by AudreyKan on 4/8/17.
                 */
                var PatternPointData = (function () {
                    function PatternPointData(patternPoint, location) {
                        this.patternPoint = patternPoint;
                        this.location = location;
                    }
                    PatternPointData.prototype.isMarker = function () {
                        return this.getPatternPoint() != null;
                    };
                    PatternPointData.prototype.getPatternPoint = function () {
                        return this.patternPoint;
                    };
                    PatternPointData.prototype.getLocation = function () {
                        return this.location;
                    };
                    return PatternPointData;
                }());
                library.PatternPointData = PatternPointData;
                PatternPointData["__class"] = "com.livewings.spotassist.library.PatternPointData";
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                /**
                 * Created by AudreyKan on 4/8/17.
                 */
                var PatternFlyData = (function () {
                    function PatternFlyData(patternPoint, location, crabAngle, iconRotation) {
                        this.crabAngle = 0;
                        this.iconRotation = 0;
                        this.patternPoint = patternPoint;
                        this.location = location;
                        this.crabAngle = crabAngle;
                        this.iconRotation = iconRotation;
                    }
                    PatternFlyData.prototype.getPatternPoint = function () {
                        return this.patternPoint;
                    };
                    PatternFlyData.prototype.getLocation = function () {
                        return this.location;
                    };
                    PatternFlyData.prototype.getCrabAngle = function () {
                        return this.crabAngle;
                    };
                    PatternFlyData.prototype.getIconRotation = function () {
                        return this.iconRotation;
                    };
                    return PatternFlyData;
                }());
                library.PatternFlyData = PatternFlyData;
                PatternFlyData["__class"] = "com.livewings.spotassist.library.PatternFlyData";
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var crossdata;
                (function (crossdata) {
                    var DefaultLocalityHelper = (function () {
                        function DefaultLocalityHelper() {
                        }
                        DefaultLocalityHelper.__static_initialize = function () { if (!DefaultLocalityHelper.__static_initialized) {
                            DefaultLocalityHelper.__static_initialized = true;
                            DefaultLocalityHelper.__static_initializer_0();
                        } };
                        DefaultLocalityHelper.prototype.getLocalString = function (resName) {
                            return DefaultLocalityHelper.stringsMap_$LI$().get(resName);
                        };
                        DefaultLocalityHelper.stringsMap_$LI$ = function () { DefaultLocalityHelper.__static_initialize(); if (DefaultLocalityHelper.stringsMap == null)
                            DefaultLocalityHelper.stringsMap = new java.util.HashMap(); return DefaultLocalityHelper.stringsMap; };
                        ;
                        DefaultLocalityHelper.__static_initializer_0 = function () {
                            DefaultLocalityHelper.stringsMap_$LI$().put("app_name", "Spot Assist");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.contact_email, "info@spotassist.com");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.loading_dropzones, "Loading ...");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.action_settings, "Settings");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.action_layers, "Layers");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.action_search, "Search");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.action_other, "Other");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_api_url, "Url to call weather API");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_api_url, "API URL");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_open_altitude, "Fully Inflated Canopy Altitude");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_open_altitude, "Open Altitude");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_cutaway_altitude, "Cutaway Altitude");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_cutaway_time, "Cutaway Time");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_canopy, "Canopy Type");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_canopy, "Canopy");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_pattern, "Pattern Direction");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_pattern, "Pattern");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_auto_adjust_upwind, "How do you want to pick landing direction when conditions(wind) change");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_auto_adjust_upwind, "Pick Landing Direction");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_wind_speed, "Speed");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_wind_speed, "Speed");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.display_layers, "Display Layers");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.ok, "OK");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.cancel, "Cancel");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.layer_windaloft, "Winds Aloft");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.layer_drift, "Drift");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.layer_pattern, "Pattern");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.search_hint, "Find Dropzone");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.drawer_open, "Open Menu");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.drawer_close, "Close Menu");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.menuitem_status, "Weather Data Status");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.menuitem_add_canopy, "Add Canopy");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.version_not_supported, "Version Is Not Supported");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.update_message, "Update");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.version_expired_title, "Trial Version Is Expired");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.version_expired_message, "Thank you for trying out Spot Assist.\nTrial version is expired.\nPlease buy a full version.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.giftcode_activated, "Giftcode is already activated on another device.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.buy_message, "Buy");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.edit_dropzone_off, "Edit");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.edit_dropzone_on, "Save");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.rta_dialog_title, "Rate Us");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.rta_dialog_message, "If you enjoy Spot Assist, would you mind taking a moment to rate it? It won\u2019t take more than a minute. Thanks for your support!");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.rta_dialog_ok, "Rate It");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.rta_dialog_cancel, "Later");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.rta_dialog_no, "No, Thanks");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.action_glideratio_logs, "Recordings");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.action_recording_status, "Status");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.dropzones_update_now_button, "Update");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.dropzones_update_later_button, "Later");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.screenshot_title, "This is how it will look like");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.switch_station_title, "Station Switch");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.target_menu_title, "Landing\nTarget");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.subscription_discosure_title, "About Subscriptions");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.subscription_discosure_text, "* Subscription length is one month\n\n* Payment will be charged to iTunes Account at confirmation of purchase\n\n* Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period\n\n* Account will be charged for renewal within 24-hours prior to the end of the current period\n\n* Cost of renewal is the same every month\n\n* You can manage subscription and turn off auto-renewal by going to your Account Settings after purchase\n\n* Please find links to Privacy Policy and Terms of Use in About screen");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.switch_station_message, "You are switching to another weather station.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.switch_station_ok, "Switch");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.delete_dropzone_title, "ACTION Dropzone");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.delete_dropzone_message, "Are you sure you want to ACTION this dropzone?");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.register_option_title, "Please register");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.register_option_text, "Do you want to register, so we could save your products and subscriptions?");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.registered_offer_title, "You are a registered user");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.registered_offer_enjoy_trial, "As a registered user, please enjoy One Week All features trial");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.wind_changed_title, "Wind has changed");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.wind_changed_message, "You adjusted your landing direction, but wind has changed.\nHow do you want to pick the landing direction for this dropzone?\n1. Adjust upwind - this is good for big square dropzones\n2. Keep it as a default for this dropzone - this is the best for small narrow landing fields\nYou can always change it later in Settings");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.register_option_thank_you_title, "Thank You for registering");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.register_option_trial_title, "Register for One Week All features trial");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.new_target_title, "Draw\nTarget");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.edit_target_title, "Target\nDetails");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.direction_menu_title, "Landing\nDirection");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.make_corridor_title, "Make\nCorridor");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.reset_corridor_title, "Remove\nCorridor");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pick_direction_title, "Pick\nDirection");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.lock_pattern_title, "Corridor Pattern Side");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.lock_pattern_message, "Do you want to keep the pattern always to one side of the dropzone?\nIf yes - the pattern will switch from Left to Right automatically to stay on this side.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.register_label_title, "Register your app.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.purchase_options_title, "Your purchase options");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.dropzones_outdated_title, "Dropzones Update");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.dropzones_outdated_message, "Spot Assist has a world-wide dropzones database. You can also update them from Settings.\n\nDo you want to update now?");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.purchase_options_month, "Buy This Feature For Life : ");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.purchase_options_life, "Buy All Features For One Month : ");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.big_picture_notification_title, "New feature for your dropzone");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.big_picture_notification_description, "Your Dropzone can be displayed on the TV screen.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.big_picture_notification_question, "Do you want to see how to use Spot Assist for entire dropzone?");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.product_ad_forecast_product_text, "See clouds altitudes");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.product_ad_forecast_snippet, "Use forecast to plan safe jumping");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.no_target_warning, "Please select your target.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pattern_warning, " Unable to calculate pattern.\n Wind speed is close to canopy speed.\n Selected canopy might fly backwards.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.about_read_blog, "Read Explanatory Articles");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.about_watch_tutorials, "Watch Video Tutorials");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.about_like_facebook, "Like Us on Facebook");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.about_play_rate, "Rate Us on Google Play");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.about_email_us, "Email Us");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide1_title, "Exit Area Feature");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide1_description, "Best exit area, based on current wind and selected canopy.\n\nYou will be surprised, but best place to exit is not always over the dropzone.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide2_title, "Pattern Feature");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide2_description, "Optimal pattern, based on current wind and selected canopy.\n\nPlan it on the ground, execute it in the sky.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide3_title, "Forecast Feature");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide3_description, "Detailed Forecast. Keeping an eye on wind gusts. Clouds at all altitudes.\n\nSometimes forecast says there are clouds, but what if there is a hole?");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide4_title, "Cutaway Finder Feature");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide4_description, "Cutaway Canopy Locator is based on the winds on the cutaway day.\n\nWithout You around the canopy is just a piece of fabric. The only thing to know is where does it drift.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide5_title, "Stay up to date");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.tour_slide5_description, "Restore previous purchases, register, learn more any time in About screen.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.corridor_slide1_title, "All dropzones are different");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.corridor_slide1_description, "Narrow dropzones have a general landing direction, even if it makes a crosswind landing.\n\nBig and square dropzones have enough space to choose any landing  direction to land upwind.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.corridor_slide2_title, "Set up dropzone landing corridor");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.corridor_slide2_description, "If dropzone has a general landing direction, Spot Assist will adjust pattern according to it.\n\nYou can also set it yourself, and we will make sure it becomes known to everybody.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.corridor_slide3_title, "Use Settings");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.corridor_slide3_description, "You can always change the way Spot Assist picks landing pattern direction.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.crowd_slide1_title, "Adjust Your dropzone landing target");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.crowd_slide1_description, "You can move the target to be at the right place.\n\nPress the target icon, hold and drag.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.crowd_slide2_title, "Share Your targets");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.crowd_slide2_description, "You can be a part of crowd sourcing the most complete world dropzones database.\n\nPlease register and we will include your dropzone too. You can also register later from About menu.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.register_option_enjoy_trial, "Enjoy One Week All features trial");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.register_option_used_trial, "Your trial expired on ");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.product_buy_action_text, "Buy");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.product_ad_exit_product_text, "See the best spot to exit");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.product_ad_exit_snippet, "Use weather data to calculate exit area");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.product_ad_canopy_product_text, "See perfect landing pattern");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.product_ad_canopy_snippet, "Use weather data to visualize landing");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_speed_units, "Pick Speed Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_speed_units, "Speed Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_distance_units, "Pick Distance Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_distance_units, "Distance Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_altitude_units, "Pick Altitude Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_altitude_units, "Altitude Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_temperature_units, "Pick Temperature Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_temperature_units, "Temperature Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_summary_time_units, "Pick Time Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pref_title_time_units, "Time Units");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.register_button_title, "Register");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.rate_app_title, "Rate Spot Assist");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.no_data_warning, "No Weather data available. Please check your connection.");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.canopy_student, "Student");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.canopy_sport, "Sport");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.canopy_pro, "Pro");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.open_by, "Open By");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pattern_no_strategy, "Based on Weather");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pattern_stay_north, "Stay on the North Side");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pattern_stay_south, "Stay on the South Side");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pattern_stay_east, "Stay on the East Side");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pattern_stay_west, "Stay on the West Side");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pattern_always_left, "Always Left Pattern");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.pattern_always_right, "Always Right Pattern");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.base_to_final, "Base To Final");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.final_l, "Final");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.downwind_to_base, "Downwind To Base");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.base, "Base");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.start_downwind, "Start Downwind");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.downwind, "Downwind");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.surface_wind_forecast_label, "Surface\nWind\nGust\n");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.surface_conditions_forecast_label, "Surface\nconditions");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.surface_temp_forecast_label, "Surface\nTemp\n");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.surface_temp_chart_label, "Temperature");
                            DefaultLocalityHelper.stringsMap_$LI$().put(com.livewings.spotassist.library.LocalityConstants.surface_latitude_chart_label, "Altitude");
                        };
                        DefaultLocalityHelper.__static_initialized = false;
                        return DefaultLocalityHelper;
                    }());
                    crossdata.DefaultLocalityHelper = DefaultLocalityHelper;
                    DefaultLocalityHelper["__class"] = "com.livewings.spotassist.library.crossdata.DefaultLocalityHelper";
                    DefaultLocalityHelper["__interfaces"] = ["com.livewings.spotassist.library.crossdata.ILocalityHelper"];
                })(crossdata = library.crossdata || (library.crossdata = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var tools;
                (function (tools) {
                    var LocalityTools = (function () {
                        function LocalityTools() {
                        }
                        LocalityTools.defaultLocalityHelper_$LI$ = function () { if (LocalityTools.defaultLocalityHelper == null)
                            LocalityTools.defaultLocalityHelper = new com.livewings.spotassist.library.crossdata.DefaultLocalityHelper(); return LocalityTools.defaultLocalityHelper; };
                        ;
                        LocalityTools.localityHelper_$LI$ = function () { if (LocalityTools.localityHelper == null)
                            LocalityTools.localityHelper = new com.livewings.spotassist.library.crossdata.DefaultLocalityHelper(); return LocalityTools.localityHelper; };
                        ;
                        LocalityTools.getLocalString = function (resName) {
                            var localString = resName;
                            if (LocalityTools.localityHelper_$LI$() != null) {
                                localString = LocalityTools.localityHelper_$LI$().getLocalString(resName);
                                if ((resName === localString)) {
                                    localString = LocalityTools.defaultLocalityHelper_$LI$().getLocalString(resName);
                                }
                            }
                            else {
                                localString = LocalityTools.defaultLocalityHelper_$LI$().getLocalString(resName);
                            }
                            return localString;
                        };
                        return LocalityTools;
                    }());
                    tools.LocalityTools = LocalityTools;
                    LocalityTools["__class"] = "com.livewings.spotassist.library.tools.LocalityTools";
                })(tools = library.tools || (library.tools = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var PatternPoint = (function () {
                        function PatternPoint(angleFromFinal, pointName, legName, bitmapResource) {
                            this.angleFromFinal = 0;
                            this.angleFromFinal = angleFromFinal;
                            this.pointName = pointName;
                            this.legName = legName;
                            this.bitmapResource = bitmapResource;
                        }
                        PatternPoint.DOWNWIND_LEG_NAME_$LI$ = function () { if (PatternPoint.DOWNWIND_LEG_NAME == null)
                            PatternPoint.DOWNWIND_LEG_NAME = com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.downwind); return PatternPoint.DOWNWIND_LEG_NAME; };
                        ;
                        PatternPoint.BASE_LEG_NAME_$LI$ = function () { if (PatternPoint.BASE_LEG_NAME == null)
                            PatternPoint.BASE_LEG_NAME = com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.base); return PatternPoint.BASE_LEG_NAME; };
                        ;
                        PatternPoint.FINAL_LEG_NAME_$LI$ = function () { if (PatternPoint.FINAL_LEG_NAME == null)
                            PatternPoint.FINAL_LEG_NAME = com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.final_l); return PatternPoint.FINAL_LEG_NAME; };
                        ;
                        PatternPoint.prototype.getAngleFromFinal = function () {
                            return this.angleFromFinal;
                        };
                        PatternPoint.prototype.getPointName = function () {
                            return this.pointName;
                        };
                        PatternPoint.prototype.getLegName = function () {
                            return this.legName;
                        };
                        PatternPoint.prototype.getBitmapResource = function () {
                            return this.bitmapResource;
                        };
                        PatternPoint.prototype.getTurnAltitude = function (dropzone) {
                            var turnAltitude = -1;
                            if ((PatternPoint.DOWNWIND_LEG_NAME_$LI$() === this.legName)) {
                                turnAltitude = dropzone.getDz_downwind_ft();
                                if (turnAltitude <= 0) {
                                    turnAltitude = PatternPoint.DEFAULT_DOWNWIND_LEG_FT;
                                }
                            }
                            else if ((PatternPoint.BASE_LEG_NAME_$LI$() === this.legName)) {
                                turnAltitude = dropzone.getDz_base_ft();
                                if (turnAltitude <= 0) {
                                    turnAltitude = PatternPoint.DEFAULT_BASE_LEG_FT;
                                }
                            }
                            else if ((PatternPoint.FINAL_LEG_NAME_$LI$() === this.legName)) {
                                turnAltitude = dropzone.getDz_final_ft();
                                if (turnAltitude <= 0) {
                                    turnAltitude = PatternPoint.DEFAULT_FINAL_LEG_FT;
                                }
                            }
                            return turnAltitude;
                        };
                        PatternPoint.prototype.setTurnAltitude = function (dropzone, turnAltitude) {
                            if ((PatternPoint.DOWNWIND_LEG_NAME_$LI$() === this.legName)) {
                                dropzone.setDz_downwind_ft(turnAltitude);
                            }
                            else if ((PatternPoint.BASE_LEG_NAME_$LI$() === this.legName)) {
                                dropzone.setDz_base_ft(turnAltitude);
                            }
                            else if ((PatternPoint.FINAL_LEG_NAME_$LI$() === this.legName)) {
                                dropzone.setDz_final_ft(turnAltitude);
                            }
                        };
                        PatternPoint.DEFAULT_DOWNWIND_LEG_FT = 1000;
                        PatternPoint.DEFAULT_BASE_LEG_FT = 600;
                        PatternPoint.DEFAULT_FINAL_LEG_FT = 300;
                        return PatternPoint;
                    }());
                    math.PatternPoint = PatternPoint;
                    PatternPoint["__class"] = "com.livewings.spotassist.library.math.PatternPoint";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var math;
                (function (math) {
                    var PatternStrategy = (function () {
                        function PatternStrategy(name, title) {
                            this.name = name;
                            this.title = title;
                        }
                        PatternStrategy.PATTERN_NO_STRATEGY_$LI$ = function () { if (PatternStrategy.PATTERN_NO_STRATEGY == null)
                            PatternStrategy.PATTERN_NO_STRATEGY = new PatternStrategy("PATTERN_NO_STRATEGY", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_no_strategy)); return PatternStrategy.PATTERN_NO_STRATEGY; };
                        ;
                        PatternStrategy.PATTERN_STAY_NORTH_$LI$ = function () { if (PatternStrategy.PATTERN_STAY_NORTH == null)
                            PatternStrategy.PATTERN_STAY_NORTH = new PatternStrategy("PATTERN_STAY_NORTH", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_stay_north)); return PatternStrategy.PATTERN_STAY_NORTH; };
                        ;
                        PatternStrategy.PATTERN_STAY_SOUTH_$LI$ = function () { if (PatternStrategy.PATTERN_STAY_SOUTH == null)
                            PatternStrategy.PATTERN_STAY_SOUTH = new PatternStrategy("PATTERN_STAY_SOUTH", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_stay_south)); return PatternStrategy.PATTERN_STAY_SOUTH; };
                        ;
                        PatternStrategy.PATTERN_STAY_EAST_$LI$ = function () { if (PatternStrategy.PATTERN_STAY_EAST == null)
                            PatternStrategy.PATTERN_STAY_EAST = new PatternStrategy("PATTERN_STAY_EAST", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_stay_east)); return PatternStrategy.PATTERN_STAY_EAST; };
                        ;
                        PatternStrategy.PATTERN_STAY_WEST_$LI$ = function () { if (PatternStrategy.PATTERN_STAY_WEST == null)
                            PatternStrategy.PATTERN_STAY_WEST = new PatternStrategy("PATTERN_STAY_WEST", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_stay_west)); return PatternStrategy.PATTERN_STAY_WEST; };
                        ;
                        PatternStrategy.PATTERN_ALWAYS_LEFT_$LI$ = function () { if (PatternStrategy.PATTERN_ALWAYS_LEFT == null)
                            PatternStrategy.PATTERN_ALWAYS_LEFT = new PatternStrategy("PATTERN_ALWAYS_LEFT", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_always_left)); return PatternStrategy.PATTERN_ALWAYS_LEFT; };
                        ;
                        PatternStrategy.PATTERN_ALWAYS_RIGHT_$LI$ = function () { if (PatternStrategy.PATTERN_ALWAYS_RIGHT == null)
                            PatternStrategy.PATTERN_ALWAYS_RIGHT = new PatternStrategy("PATTERN_ALWAYS_RIGHT", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_always_right)); return PatternStrategy.PATTERN_ALWAYS_RIGHT; };
                        ;
                        PatternStrategy.valueOf = function (name) {
                            {
                                var array135 = PatternStrategy.values();
                                for (var index134 = 0; index134 < array135.length; index134++) {
                                    var p = array135[index134];
                                    {
                                        if ((p.getName() === name)) {
                                            return p;
                                        }
                                    }
                                }
                            }
                            return null;
                        };
                        PatternStrategy.values = function () {
                            return [PatternStrategy.PATTERN_NO_STRATEGY_$LI$(), PatternStrategy.PATTERN_STAY_NORTH_$LI$(), PatternStrategy.PATTERN_STAY_SOUTH_$LI$(), PatternStrategy.PATTERN_STAY_EAST_$LI$(), PatternStrategy.PATTERN_STAY_WEST_$LI$(), PatternStrategy.PATTERN_ALWAYS_LEFT_$LI$(), PatternStrategy.PATTERN_ALWAYS_RIGHT_$LI$()];
                        };
                        PatternStrategy.prototype.getName = function () {
                            return this.name;
                        };
                        PatternStrategy.prototype.getTitle = function () {
                            return this.title;
                        };
                        PatternStrategy.prototype.toString = function () {
                            return this.getName();
                        };
                        return PatternStrategy;
                    }());
                    math.PatternStrategy = PatternStrategy;
                    PatternStrategy["__class"] = "com.livewings.spotassist.library.math.PatternStrategy";
                })(math = library.math || (library.math = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
/* Generated from Java with JSweet 1.2.0 - http://www.jsweet.org */
var com;
(function (com) {
    var livewings;
    (function (livewings) {
        var spotassist;
        (function (spotassist) {
            var library;
            (function (library) {
                var json;
                (function (json) {
                    (function (Pattern) {
                        Pattern[Pattern["LEFT"] = 0] = "LEFT";
                        Pattern[Pattern["RIGHT"] = 1] = "RIGHT";
                    })(json.Pattern || (json.Pattern = {}));
                    var Pattern = json.Pattern;
                    var Pattern_$WRAPPER = (function () {
                        function Pattern_$WRAPPER(_$ordinal, _$name, label, index) {
                            this._$ordinal = _$ordinal;
                            this._$name = _$name;
                            this.index = 0;
                            this.label = label;
                            this.index = index;
                        }
                        Pattern_$WRAPPER.prototype.getLabel = function () {
                            return this.label;
                        };
                        Pattern_$WRAPPER.prototype.getIndex = function () {
                            return this.index;
                        };
                        Pattern_$WRAPPER.prototype.name = function () { return this._$name; };
                        Pattern_$WRAPPER.prototype.ordinal = function () { return this._$ordinal; };
                        return Pattern_$WRAPPER;
                    }());
                    json.Pattern_$WRAPPER = Pattern_$WRAPPER;
                    Pattern["__class"] = "com.livewings.spotassist.library.json.Pattern";
                    Pattern["__interfaces"] = ["java.lang.Comparable", "java.io.Serializable"];
                    Pattern["_$wrappers"] = [new Pattern_$WRAPPER(0, "LEFT", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_title_left), 0), new Pattern_$WRAPPER(1, "RIGHT", com.livewings.spotassist.library.tools.LocalityTools.getLocalString(com.livewings.spotassist.library.LocalityConstants.pattern_title_right), 1)];
                })(json = library.json || (library.json = {}));
            })(library = spotassist.library || (spotassist.library = {}));
        })(spotassist = livewings.spotassist || (livewings.spotassist = {}));
    })(livewings = com.livewings || (com.livewings = {}));
})(com || (com = {}));
com.livewings.spotassist.library.math.PatternStrategy.PATTERN_ALWAYS_RIGHT_$LI$();
com.livewings.spotassist.library.math.PatternStrategy.PATTERN_ALWAYS_LEFT_$LI$();
com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_WEST_$LI$();
com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_EAST_$LI$();
com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_SOUTH_$LI$();
com.livewings.spotassist.library.math.PatternStrategy.PATTERN_STAY_NORTH_$LI$();
com.livewings.spotassist.library.math.PatternStrategy.PATTERN_NO_STRATEGY_$LI$();
com.livewings.spotassist.library.math.PatternPoint.FINAL_LEG_NAME_$LI$();
com.livewings.spotassist.library.math.PatternPoint.BASE_LEG_NAME_$LI$();
com.livewings.spotassist.library.math.PatternPoint.DOWNWIND_LEG_NAME_$LI$();
com.livewings.spotassist.library.tools.LocalityTools.localityHelper_$LI$();
com.livewings.spotassist.library.tools.LocalityTools.defaultLocalityHelper_$LI$();
com.livewings.spotassist.library.crossdata.DefaultLocalityHelper.stringsMap_$LI$();
com.livewings.spotassist.library.crossdata.DefaultLocalityHelper.__static_initialize();
com.livewings.spotassist.library.json.Metar.dummyPhenomenas_$LI$();
com.livewings.spotassist.library.json.Forecast.dummyPhenomenas_$LI$();
com.livewings.spotassist.library.math.SkydiveMath.TAG_$LI$();
