define("@ember/string/helpers/loc", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loc = loc;
  function loc(params /*, hash*/) {
    var _Ember$String;
    return (_Ember$String = Ember.String).loc.apply(_Ember$String, (0, _toConsumableArray2.default)(params));
  }
  var _default = _exports.default = Ember.Helper.helper(loc);
});