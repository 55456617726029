define('ember-aria-slider/components/aria-slider', ['exports', 'ember-aria-slider/templates/components/aria-slider'], function (exports, _ariaSlider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _ariaSlider.default,
    classNames: ['aria-widget-slider'],
    focusIn: function focusIn() {
      this.set('focusClass', 'focus');
    },
    focusOut: function focusOut() {
      this.set('focusClass', '');
    }
  });
});